import type { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from '../../../../components/Data-Table/datatable-header'
import { type SeedTransaction } from '../../seed-dealer/types'
import dayjs from 'dayjs'
import { type ServiceTransaction } from '../../service-provider/types'
import { type ChemicalTransaction } from '../../chemical-dealer/types'

export const reportsColumns = (
  t: (key: string) => string
): Array<ColumnDef<SeedTransaction | ServiceTransaction | ChemicalTransaction>> => [
  {
    id: 'transaction id',
    accessorFn: (row) => row.transaction_id,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('transaction id')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('report.tid')} />
  },
  {
    id: 'date',
    accessorFn: (row) => row.date,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    sortingFn: (rowA, rowB, columnId) =>
      dayjs(rowB.getValue(columnId)).unix() - dayjs(rowA.getValue(columnId)).unix(),
    sortDescFirst: true,
    cell: ({ row }) => (
      <div className='uppercase'>{dayjs(row.getValue('date')).format('DD-MM-YYYY')}</div>
    ),
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('report.date')} />
  }
,
  {
    id: 'voucher_tracking_no',
    accessorFn: (row) => row.voucher_tracking_no === null ? 'N/A' : row.voucher_tracking_no,
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div>{row.getValue('voucher_tracking_no')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('report.ndb')} />
  },
  {
    id: 'firstname',
    accessorFn: (row) => row.firstname,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('firstname')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('report.fName')} />
  },
  {
    id: 'surname',
    accessorFn: (row) => row.surname,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('surname')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('report.sName')} />
  },
  {
    id: 'total cost',
    accessorFn: (row) => row.amount,
    filterFn: 'includesString',
    enableHiding: true,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('report.tCost')} />,
    cell: ({ row }) => {
      const amountString = String(row.getValue('total cost')).replace(/,/g, '')
      const amount = parseFloat(amountString)
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  }
]
