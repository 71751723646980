import type React from 'react'
import { type ReactElement, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetInventoryItem } from '../../../../farmer/inventory/api/getInventoryItem'
import {
  type Implement,
  type PoweredImplement,
  type Tractor
} from '../../../../farmer/inventory/types'
import { findMatchingItem } from '../../../../farmer/inventory/util/findMatchingItem'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  Loader
} from '../../../../../components/ui'
import { RegisterTractors } from '../components/RegisterTractor'
import { RegisterPoweredImplements } from '../components/RegisterPoweredImplements'
import { RegisterNonPoweredImplements } from '../components/RegisterNonPoweredImplements'
import { useAuthenticationStore } from '../../../../../stores/useAuthenticationStore'
import { useStaticDataStore } from '../../../../../stores/useStaticDataStore'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const ModifyInventoryMerchant: React.FC = () => {
  const { inventoryId } = useParams<{ inventoryId: string }>()
  const { data, isLoading } = useGetInventoryItem(Number(inventoryId))
  const [inventoryItem, setInventoryItem] = useState<Tractor | PoweredImplement | Implement>()
  const inventoryData = data?.data[0]
  const navigate = useNavigate()
  const { role } = useAuthenticationStore()
  const { tractors, nonPoweredImplements, poweredImplements } = useStaticDataStore()
  const flags = useFlags()
  useEffect(() => {
    if (tractors != null && nonPoweredImplements != null && poweredImplements != null) {
      setInventoryItem(
        findMatchingItem(inventoryData, tractors, nonPoweredImplements, poweredImplements)
      )
    }
  }, [tractors, nonPoweredImplements, poweredImplements, inventoryData])

  const isMerchant = role === 1010
  const isView =
    inventoryData?.item_verification_status === 'VERIFIED' ||
    inventoryData?.item_verification_status === 'REJECTED' ||
    inventoryData?.item_verification_status === 'TO BE REASSESSED'

  const renderContent = (): ReactElement | null => {
    if (isLoading || inventoryItem == null) {
      return null
    }
    switch (inventoryData?.inventory_type_id) {
      case 1001:
        return (
          <RegisterPoweredImplements
            key={inventoryData?.inventory_id}
            inventoryData={inventoryData}
            inventoryItem={inventoryItem}
            isView={isView}
            isMerchant={isMerchant}
          />
        )
      case 1002:
        return (
          <RegisterNonPoweredImplements
            key={inventoryData?.inventory_id}
            inventoryData={inventoryData}
            inventoryItem={inventoryItem}
            isView={isView}
            isMerchant={isMerchant}
          />
        )
      default:
        return (
          <RegisterTractors
            key={inventoryData?.inventory_id}
            inventoryData={inventoryData}
            inventoryItem={inventoryItem}
            isView={isView}
            isMerchant={isMerchant}
          />
        )
    }
  }

  if (isLoading) {
    return (
      <div className='h-full grid place-items-center'>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <div className={'flex justify-between items-center border-b border-border p-4 mb-10'}>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  className={'text-lg'}
                  to={`${
                    role === 2001
                      ? '/service-provider/inventory'
                      : role === 1001
                      ? '/officer/inventory'
                      : '/inventory'
                  }`}
                >
                  Inventory
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className={'text-lg'}>
                {` ${
                  inventoryData?.inventory_type_id?.toString() === '1001'
                    ? 'Powered Implement'
                    : inventoryData?.inventory_type_id?.toString() === '1002'
                    ? 'Non-Powered Implement'
                    : 'Tractor'
                }`}
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        {isView && role !== 1001 && flags.inventoryTransfer && (
          <Button
            variant='success'
            onClick={() => {
              if (role === 2001) {
                navigate(`/service-provider/inventory/history/${inventoryData?.inventory_id ?? ''}`)
              } else {
                navigate(`/inventory/history/${inventoryData?.inventory_id ?? ''}`)
              }
            }}
            size={'default'}
          >
            View History
          </Button>
        )}
      </div>
      {renderContent()}
    </>
  )
}
