import type React from 'react'
import { useState } from 'react'
import { Button, Tooltip, TooltipContent, TooltipTrigger } from 'components/ui'
import { Globe } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'

export const LanguageToggle: React.FC = () => {
  const { i18n } = useTranslation()
  const currentLanguageCode = i18n.language
  const [currentLang, setCurrentLang] = useState<string>(currentLanguageCode)

  const toggleLanguage = (newLang: string): void => {
    void i18n.changeLanguage(newLang)
    setCurrentLang(newLang)
  }
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  if (isSmallScreen) {
    return (
      <Tooltip>
        <TooltipTrigger>
          <Button
            variant={'ghost'}
            size={'xs'}
            className='bg-transparent font-semibold text-gray-600 '
            onClick={() => {
              toggleLanguage(currentLanguageCode === 'en' ? 'sw' : 'en')
            }}
          >
            <Globe size={'12px'} />
            {currentLanguageCode === 'en' ? 'EN' : 'SW'}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className='font-semibold text-gray-600 '>Switch Language to {currentLanguageCode === 'en' ? 'Setswana' : 'English'}</p>
        </TooltipContent>
      </Tooltip>
    )
  }

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button
          variant={'ghost'}
          size={'xs'}
          className="bg-transparent font-semibold  text-gray-600"
          onClick={() => {
            toggleLanguage(currentLanguageCode === 'en' ? 'sw' : 'en')
          }}
        >
          <Globe />
          <p className="font-semibold text-gray-600 ">
            {currentLanguageCode === 'en' ? 'English' : 'Setswana'}
          </p>
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p>Switch Language to {currentLanguageCode === 'en' ? 'Setswana' : 'English'}</p>
      </TooltipContent>
    </Tooltip>
  )
}

