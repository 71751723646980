import type React from 'react'
import { type ReactElement, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetInventoryItem } from '../api/getInventoryItem'
import { findMatchingItem } from '../util/findMatchingItem'
import { RegisterTractors } from './RegisterTractors'
import { RegisterPoweredImplements } from './RegisterPoweredImplements'
import { RegisterNonPoweredImplements } from './RegisterNonPoweredImplements'
import type { Implement, PoweredImplement, Tractor } from '../types'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  Loader
} from '../../../../components/ui'
import { useGetVerifiedFarms } from '../util'
import { useTranslation } from 'react-i18next'
import { useAuthenticationStore } from '../../../../stores/useAuthenticationStore'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const ModifyInventory: React.FC = () => {
  const { inventoryId } = useParams<{ inventoryId: string }>()
  const { data, isLoading } = useGetInventoryItem(Number(inventoryId))
  const navigate = useNavigate()
  const { t } = useTranslation('inventory')
  const { role } = useAuthenticationStore()
  const { tractors, nonPoweredImplements, poweredImplements } = useStaticDataStore()
  const flags = useFlags()
  const [inventoryItem, setInventoryItem] = useState<Tractor | PoweredImplement | Implement>()
  const inventoryData = data?.data[0]
  const isMerchant = role === 1010
  const isView =
    inventoryData?.item_verification_status === 'VERIFIED' ||
    inventoryData?.item_verification_status === 'REJECTED' ||
    inventoryData?.item_verification_status === 'TO BE REASSESSED'

  let verifiedFarms: any[] = []
  let isFarmsLoading = false

  if (!isMerchant) {
    const farmsData = useGetVerifiedFarms(Number(inventoryData?.farmer_id))
    verifiedFarms = farmsData.verifiedFarms
    isFarmsLoading = farmsData.isLoading
  }

  useEffect(() => {
    if (
      inventoryData != null &&
      tractors != null &&
      nonPoweredImplements != null &&
      poweredImplements != null
    ) {
      setInventoryItem(
        findMatchingItem(inventoryData, tractors, nonPoweredImplements, poweredImplements)
      )
    }
  }, [inventoryData])

  const renderContent = (): ReactElement | null => {
    if (isLoading || isFarmsLoading || inventoryItem == null) {
      return null
    }

    switch (inventoryData?.inventory_type_id) {
      case 1001:
        return (
          <RegisterPoweredImplements
            key={inventoryData?.inventory_id}
            inventoryData={inventoryData}
            inventoryItem={inventoryItem}
            isView={isView}
            isMerchant={isMerchant}
            farmsList={verifiedFarms}
          />
        )
      case 1002:
        return (
          <RegisterNonPoweredImplements
            key={inventoryData?.inventory_id}
            inventoryData={inventoryData}
            inventoryItem={inventoryItem}
            isView={isView}
            isMerchant={isMerchant}
            farmsList={verifiedFarms}
          />
        )
      default:
        return (
          <RegisterTractors
            key={inventoryData?.inventory_id}
            inventoryData={inventoryData}
            inventoryItem={inventoryItem}
            isView={isView}
            isMerchant={isMerchant}
            farmsList={verifiedFarms}
          />
        )
    }
  }

  if (isLoading || isFarmsLoading) {
    return (
      <div className='h-full grid place-items-center'>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <div className={'flex justify-between items-center border-b border-border p-4 mb-10'}>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  className={'text-lg'}
                  to={`${
                    role === 1000
                      ? '/farmer/inventory'
                      : role === 1001
                      ? '/officer/inventory'
                      : 'inventory'
                  }`}
                >
                  {t('inventory.inventory')}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className={'text-lg'}>
                {` ${
                  inventoryData?.inventory_type_id?.toString() === '1001'
                    ? `${t('inventory.pwrd')}`
                    : inventoryData?.inventory_type_id?.toString() === '1002'
                    ? `${t('inventory.nonPwrd')}`
                    : `${t('inventory.tractor')}`
                }`}
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        {isView && role !== 1001 && flags.inventoryTransfer && (
          <Button
            variant='success'
            onClick={() => {
              if (role === 1000) {
                navigate(`/farmer/inventory/history/${inventoryData?.inventory_id ?? ''}`)
              } else {
                navigate(`/inventory/history/${inventoryData?.inventory_id ?? ''}`)
              }
            }}
            size={'default'}
          >
            {t('inventory.history')}
          </Button>
        )}
      </div>
      {renderContent()}
    </>
  )
}
