// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react'
import { replayIntegration } from '@sentry/react'
import { CLIENT_SIDE_ID } from 'config'
import { API_URL } from 'config/firebase'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { App } from 'pages/App'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

import './index.css'
import { Toaster } from './components/ui/toaster'
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react'
import Passwordless from 'supertokens-auth-react/recipe/passwordless'
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword'
import Session from 'supertokens-auth-react/recipe/session'
import { NoInternetPage } from './components/Errors/NoInternet'
import { TooltipProvider } from './components/ui'

const DSN = import.meta.env.VITE_SENTRY_DSN as string
const ENV = import.meta.env.VITE_SENTRY_ENV as string
const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL as string
const basePath = import.meta.env.VITE_BASE_PATH as string
const appName = import.meta.env.VITE_APP_NAME as string
const isProd = import.meta.env.PROD

Sentry.init({
  dsn: DSN,
  // use for debugging
  // debug: true,
  environment: ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    replayIntegration({
      minReplayDuration: 15000,
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [API_URL],
      networkRequestHeaders: ['X-Custom-Header', 'app-token', 'authToken'],
      networkResponseHeaders: ['X-Custom-Header', 'correlation-id', 'date'],
      mutationBreadcrumbLimit: 1000,
      mutationLimit: 1500
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://temo-frontend-development.azurewebsites.net/',
    'https://temoletlotlo-training.com/',
    'https://temoletlotlo.com/',
    /^https:\/\/yourserver\.io\/api/
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // 1.0 for 100%
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

SuperTokens.init({
  appInfo: {
    appName,
    apiDomain: API_URL,
    apiBasePath: basePath,
    websiteDomain: isProd ? WEBSITE_URL : 'http://localhost:3000'
  },
  getRedirectionURL: async (context): Promise<null> => {
    return null
  },
  recipeList: [
    Passwordless.init({
      contactMethod: 'PHONE',
      signInUpFeature: {
        defaultCountry: 'BW',
        resendEmailOrSMSGapInSeconds: 30
      }
    }),
    EmailPassword.init(),
    Session.init()
  ]
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const renderApp = async () => {
  const LProvider = await asyncWithLDProvider({
    clientSideID: CLIENT_SIDE_ID,
    context: {
      kind: 'user',
      key: 'moa-frontend',
      name: 'MOA',
      email: 'user@moa.bw'
    },
    flags: {
      'Landing-Guidelines': true,
      'Cropping-Plan': false,
      'Omang-Validation': true,
      'Company-Registration': false,
      'Lease-Termination': false,
      'Merchant-Inventory': false,
      'SEED-DEALERS': true,
      'chemical-dealers': true,
      'service-providers': true,
      'inventory-transfer': false,
      maintenance: false,
      loan: false,
      INVENTORY: false,
      GRANT: false,
      'CROPPING-PLAN-HEADER': false,
      'merchant-inventory-transaction': false,
      'Micro-Shelling': false,
      'Micro-Harvesting': false,
      'Micro-Packaging': false,
      'Farm-History': false,
      'Soil-Correction': false,
      'Soil-Sample-Request': false,
      'Cropping-Plan-Officer': false,
      lease: false,
      'Cp-Creation': false,
      ENABLE_MICROSCALE_FUNGICIDES: false,
      DISABLE_MICROSCALE_ACCESS_TO_SUBPROCESSES: false,
      AUTHENTICATE_USER: false
    }
  })

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <NoInternetPage>
        <LProvider>
          <SuperTokensWrapper>
            <TooltipProvider delayDuration={300}>
              <App />
              <Toaster />
            </TooltipProvider>
          </SuperTokensWrapper>
        </LProvider>
      </NoInternetPage>
    </React.StrictMode>
  )
}

void renderApp()
