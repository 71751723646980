import type React from 'react'
import { useSuperTokens } from '../../features/authentication/hooks/useSuperTokens'
import { Button, Text } from '../ui'
import { MonitorX } from 'lucide-react'

export const SessionExpired = (): React.ReactElement => {
  const { logOut } = useSuperTokens()

  const goToLogin = (): void => {
    logOut()
  }

  return (
    <main className={'h-screen w-full flex flex-col justify-center items-center gap-8'}>
      <MonitorX size={40} />
      <div className={'flex flex-col justify-center items-center gap-4'}>
        <Text className={'text-center'} size={'xLarge'} variant={'error'}>
          Session Expired
        </Text>
        <Text className={'text-center'}>
          Sorry your current session has expired. <br />
          Please login again.
        </Text>
      </div>
      <Button onClick={goToLogin} size={'sm'}>
        Login
      </Button>
    </main>
  )
}
