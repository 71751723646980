import type React from 'react'
import { Button, Text } from '../ui'

export const ErrorPage: React.FC = () => {
  return (
    <div className='flex flex-col items-center justify-center w-full h-full'>
      <Text size={'xxLarge'} className={'text-[clamp(4rem,15vw,10rem)]'} variant={'error'}>
        OOPS!
      </Text>
      <Text size={'large'} variant={'error'}>
        Something went wrong :(
      </Text>
      <Text size={'medium'}>An unexpected error has occurred.</Text>
      <Button
        variant='primary'
        className='w-full max-w-[400px] flex justify-center space-x-4 mt-8'
        onClick={() => {
          window.location.reload()
        }}
      >
        Refresh
      </Button>
    </div>
  )
}
