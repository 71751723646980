import type React from 'react'
import { useState } from 'react'
import type { Table } from '@tanstack/react-table'
import { VoucherForm } from './VoucherForm'
import { type SelectedSeeds } from '../types'
import { useRedeemSeedsVoucher } from '../hooks/useRedeemSeedsVoucher'
import { RedeemVoucherOtpForm } from '../../components/RedeemVoucherOtpForm'
import { VoucherRedeemed } from '../../components/VoucherRedeemed'
import { NotAvailable } from '../../../../components/Layout/NotAvailable'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface VoucherRedemptionProps<TData> {
  table: Table<TData>
}

export function VoucherRedemption<TData>({
  table
}: VoucherRedemptionProps<TData>): React.ReactNode {
  const flags = useFlags()
  const { isLoading, getContact, contact, isOtpSent, isVoucherRedeemed, voucher, redeemVoucher } =
    useRedeemSeedsVoucher()
  const [selectedSeeds, setSelectedSeeds] = useState<SelectedSeeds[]>([])

  const redeem = (): void => {
    redeemVoucher(selectedSeeds)
  }

  if (
    (flags.seedDealers === false)
  ) {
    return <NotAvailable />
  }
  if (isVoucherRedeemed) {
    return <VoucherRedeemed voucher={voucher} />
  }

  if (isOtpSent) {
    return <RedeemVoucherOtpForm contact={contact ?? ''} redeem={redeem} />
  }

  return (
    <VoucherForm
      loading={isLoading}
      getContact={getContact}
      setSelectedData={setSelectedSeeds}
      className={'flex flex-col gap-4'}
      table={table}
    />
  )
}
