import { useTranslation } from 'react-i18next'
import { Text } from 'components/ui'
import { Check, Info } from 'lucide-react'
import type * as React from 'react'

interface LoanElligibilityProps {
  isEligible: boolean
  notEligibleReasons?: string[]
}

export const LoanElligibility: React.FC<LoanElligibilityProps> = ({
  isEligible,
  notEligibleReasons
}) => {
  const { t } = useTranslation('loanManagement')

  return (
    <div className='w-full flex flex-col mb-4 gap-4'>
      <Text size='medium'>{t('home.new')}</Text>

      {isEligible ? (
        <div className='bg-card-secondary flex flex-col gap-4 p-4 rounded-md border border-success'>
          <Text className='flex gap-2 flex-start items-start'>
            <Check className='mt-1 h-5 w-5 text-success' />
            {t('home.eligible')}
          </Text>
          <div className={'flex flex-col gap-4'}>
            <Text variant={'error'}>{t('home.disclaimer')}:</Text>
            <Text>{t('home.ensure')}</Text>
          </div>
        </div>
      ) : (
        <div className='bg-card-secondary flex flex-col gap-4 p-4 rounded-md border border-destructive'>
          <Text className='flex gap-2 flex-start items-start'>
            <Info className='mt-1 h-5 w-5 text-destructive' />
            {t('home.notEligible')}:
          </Text>
          <div className={'flex flex-col ml-8'}>
            {notEligibleReasons?.map((reason, index) => (
              <li key={index}>
                <Text size={'small'}>{reason}</Text>
              </li>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
