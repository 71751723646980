import React, { useEffect, useState } from 'react'
import { Button, Card, Text } from '../../../../components/ui'
import { Loader } from '../../../../components/ui'
import { ErrorPage } from '../../../../components/Errors/ErrorPage'
import { useMerchantStore } from '../../../../stores/useMerchantStore'
import { useGetReports } from '../api/getReports'
import { NoTransactions } from '../../components/NoTransactions'
import { reportsColumns } from 'features/merchant/components/report/columns'
import { ReportsTable } from '../components/report/ReportsTable'
import { useTranslation } from 'react-i18next'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'
import { Info } from 'lucide-react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate } from 'react-router-dom'
import { ServiceTransaction } from '../types'

export const ServiceProviderReports: React.FC = () => {
  const { merchant } = useMerchantStore()
  const { t } = useTranslation('merchant')
  const { data: reports, isLoading, error } = useGetReports(merchant?.merchant_id)
  const { hasTractor: storeHasTractor } = useStaticDataStore()
  const flags = useFlags()
  const navigate = useNavigate()

  const transactions = reports?.data?.filter((report) => report?.service_list.length >= 1) ;

  const [hasTractor, setHasTractor] = useState(storeHasTractor)


  useEffect(() => {
    setHasTractor(storeHasTractor)
  }, [storeHasTractor])
  if (isLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }



  if (error != null) {
    if (error?.response?.status === 404) {
      return <NoTransactions />
    } else {
      return <ErrorPage />
    }
  }
  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'}>
      <Text size={'large'}>{t('seed.tr')}</Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-6 lg:gap-8 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        {(!flags.merchantInventoryTransaction || !hasTractor) ? (
          <div className='flex flex-col items-center justify-center gap-4'>
            <Text size={'medium'} className={'text-center'}>
              To view your transactions you need a verified Tractor
            </Text>
            <div className='flex items-center'>
              <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
              <Text variant='secondary' size='small' className=''>
                {`You need to have a Tractor that is verified to transact ${
                  flags.merchantInventory === false
                    ? 'however inventory registration is unavailable at the moment'
                    : ''
                }`}
              </Text>
            </div>
            {flags.merchantInventory && (
              <Button
                size='xs'
                variant='success'
                onClick={() => {
                  navigate('/service-provider/inventory')
                }}
              >
                View Inventory
              </Button>
            )}
          </div>
        ) : (
          <>
            <Text size={'medium'} variant={'bodyTextLight'}>
              {t('seed.trList')}
            </Text>
            <ReportsTable columns={reportsColumns(t)} data={transactions as ServiceTransaction[]} />
          </>
        )}


      </Card>
    </div>
  )
}
