import { type ChemicalListWithID } from 'features/farmer/cropping-plan/types'

export enum ROLES {
  FARMER = 1000,
  OFFICER = 1001,
  MECHANIZATION = 1010,
  LAB = 2000,
  DRAUGHPOWER = 2001,
  SEEDDEALER = 2004,
  AGROCHEMICALDEALERS = 2003,
  AGROCHEMICALSEEDDEALERS = 2002
}

export interface ApiResponse<T> {
  success: boolean
  status: number
  message: string
  data: T[]
  errors: []
}

export interface ApiResponseWithObject<T> {
  success: boolean
  status: number
  message: string
  data: T
}

export interface FileReference {
  file_reference: string
}

export interface Bank {
  banks: BankNames[]
  bank_information: BankInformation[]
}

export interface BankNames {
  bank_id: number
  bank_name: string
}

export interface BankInformation {
  bank_id: number
  bank_name: string
  bank_branch_id: number
  bank_branch_name: string
  bank_branch_location: string
  bank_branch_contact_number: string | null
  branch_code: number
}

export interface Farm {
  farmer_id: number
  farm_id: number
  last_updated_date: Date
  last_updated_by: number
  district_id: number
  sub_district_id: number
  ext_area_id: number
  village_id: number
  nickname: string
  size: number
  leased_size: number
  available_size: number
  unit_of_measure: number
  farm_name: string
  plot_number: string
  attached_leases: number
  village_name: string
  extension_area_name: string
  status: 'verified' | 'rejected' | 'pending' | 'pending edit verification'
  farmer_name: string
  farmer_contact: string
  message: string
}

export interface TitleDeedFarm {
  DEED_NUMBER: string
  PLOT_NUMBER: number
  FARM_SIZE: number
  VILLAGE_ID: number
  VILLAGE_NAME: string
  EXT_AREA_ID: number
  EXTENSION_AREA_NAME: string
  SUB_DISTRICT_ID: number
  SUB_DISTRICT_NAME: string
  DISTRICT_ID: number
  DISTRICT_NAME: string
}

export interface Village {
  village_id: number
  village_name: string
  extension_area_id: number
  extension_area_name: string
  sub_district_id: number
  sub_district_name: string
  district_id: number
  district_name: string
}

export interface Farmer {
  farmer_id: number
  user_id: number
  individual_pob: string
  company_type: null
  individual_expiry_date: Date
  company_directors: null
  company_ownership_type: null
  last_updated_date: Date
  last_updated_by: number
  company_reg_no: null
  contact: string
  physical_addr: string | null
  postal_addr: string | null
  firstname: string
  mid_name: null
  surname: string
  omang: string
  residential_permit_no: null
  farmer_type: 1 | 2 | 3
  individual_gender: string
  individual_dob: Date
  company_name: string | null
  company_reg_date: null
  verification_status: string
  email: string
  class_id: 'DESTITUTE' | 'NONTAXPAYER' | 'TAXPAYER' | null
}

export interface Lessee {
  farmer_type: 1 | 2 | 3
  firstname: string
  mid_name: string | null
  surname: string
  company_name: string | null
  class_id: 'DESTITUTE' | 'NONTAXPAYER' | 'TAXPAYER' | null
}

export interface Officer {
  id: number | null
  user_id: number
  extension_officer_id: number
  firstname: string
  middlename: null
  surname: string
  contact: string
  email: string
  ext_area_id: number | null
  extension_area_name: string | null
  sub_district_id: number | null
  sub_district_name: string | null
  district_id: number
  district_name: string
}

export interface MechanizationOfficer {
  user_id: number
  id: number
  extension_officer_id: number
  firstname: string
  middlename: null
  surname: string
  contact: string
  email: string
  district_id: number
  district_name: string
}

export interface Merchant {
  merchant_id: number
  user_id: number
  merchant_type_description: string
  uin: string
  license_no: string
  merchant_name: string
  trade_name: string
  location: string
  physical_address: string
  postal_address: string
  email: string
  contact: string
  merchant_type: number
  user_role: number
  active: string | null
  merchant_pob: string | null
  merchant_expiry_date: Date | null
  merchant_firstname: string | null
  merchant_midname: string | null
  merchant_surname: string | null
  merchant_omang: string | null
  merchant_dob: Date | null
  merchant_gender: string | null
}

export interface Lease {
  lease_id: number
  farm_id: number
  origin_farmer_id: number
  dest_farmer_id: number
  commencement_date: string
  expiry_date: string
  lease_message: null | string
  status_desc:
    | 'INITIATED BY LESSOR'
    | 'ACCEPTED BY LESSEE'
    | 'REJECTED BY LESSEE'
    | 'VERIFIED BY EXTENSION OFFICER'
    | 'TERMINATION INITIATED BY LESSOR'
    | 'TERMINATION INITIATED BY LESSEE'
    | 'TERMINATION ACCEPTED BY LESSEE'
    | 'TERMINATION ACCEPTED BY LESSOR'
    | 'RENEWAL INITIATED BY LESSEE'
    | 'RENEWAL ACCEPTED BY LESSOR'
    | 'EXPIRED'
  origin_farmer_contact: string
  origin_farmer_physical_address: string
  origin_farmer_postal_address: string
  origin_farmer_email: string | null
  destination_farmer_contact: string
  destination_farmer_physical_address: string | null
  destination_farmer_postal_address: string | null
  dest_farmer_email: string | null
  farm_name: string
  farm_nickname: string
  plot_number: string
  village_id: number
  village: string
  sub_district_id: number
  sub_district_name: string
  extension_area: string
  ext_area_id: number
  district_id: number
  district_name: string
  farm_size: number
  leased_size: number
  dest_farmer_name: string
  origin_farmer_name: string
  origin_farmer_firstname: string
  origin_farmer_mid_name: string | null
  origin_farmer_surname: string
  origin_farmer_gender: string
  origin_farmer_dob: Date | string
  origin_farmer_omang: string
  origin_company_type: string | null
  origin_company_directors: DirectorDetails[] | null
  origin_company_reg_no: string | null
  origin_shareholder_details: ShareHoldersDetails[] | null
  dest_farmer_firstname: string
  dest_farmer_mid_name: string | null
  dest_farmer_surname: string
  dest_farmer_gender: string
  dest_farmer_dob: Date | string
  dest_farmer_omang: number
  dest_company_type: string | null
  dest_company_directors: DirectorDetails[] | null
  dest_company_reg_no: string | null
  destination_shareholder_details: ShareHoldersDetails[] | null
  dest_farmer_class: 'DESTITUTE' | 'NONTAXPAYER' | 'TAXPAYER' | null
  origin_farmer_class: 'DESTITUTE' | 'NONTAXPAYER' | 'TAXPAYER' | null
  new_termination_date: string
  origin_farmer_type: 1 | 2 | 3 | null
  origin_farmer_company_name: string | null
  dest_farmer_company_name: string | null
  dest_farmer_type: 1 | 2 | 3 | null
}

export const enum CroppingPlanHeaderStatus {
  Draft = 'Draft',
  Submitted_For_Review = 'Submitted CP Verification',
  Awaiting_Farm_Assessment = 'Awaiting Farm Assessment',
  Rejected_CP_Verification = 'Rejected - CP Verification',
  RAI_CP_Verification = 'RAI - CP Verification',
  Approved = 'Approved',
  RAI_Farm_Assessment = 'RAI - Farm Assessment',
  Rejected_Farm_Assessment_Verification = 'Rejected - Farm Assessment'
}

export const enum ManualWeedMethods {
  HumanBased = 'A5',
  InterrowCultivation = 'A6'
}

export const enum ManualPestMethods {
  HumanBased = 'A5'
}

export const enum WeedingChemicalMethods {
  Drone = 'A1',
  Plane = 'A2',
  Tractor = 'A3',
  Knapsack = 'A4'
}

export const enum WeedAndPestApplicationStage {
  BeforePlanting = 'BP',
  PreEmergence = 'PrE',
  PostEmergence = 'PoE',
  BeforePlantingAndPostEmergence = 'BPPoE',
  PreEmergenceAndPostEmergence = 'PrEPoE'
}

export const enum PestManualSchedule {
  BeforePlanting = 'BP',
  PreEmergence = 'PrE',
  PostEmergence = 'PoE',
  BeforePlantingAndPostEmergence = 'BPPoE',
  PreEmergenceAndPostEmergence = 'PrEPoE'
}

export const enum PestChemicalMethods {
  Drone = 'A1',
  Plane = 'A2',
  Tractor = 'A3',
  Knapsack = 'A4'
}

export interface CroppingPlan {
  cropping_plan_id: number
  farmer_id: number
  farm_id: number
  hectarage: number
  is_verified: CroppingPlanHeaderStatus
  plough_season: string
  crop_id: number
  crop_name: string
  family_name: string
  ssr_id: string
  total_cost: string
  comments: string | null
}

export interface Seeding {
  seed_detail_id: number
  seed_type: 'OPV' | 'HYBRID'
  cost: number
  yield: string
  days_to_maturity: string
  sowing_rate: string
  variety: string
  anticipated_returns: number
}

export interface Harvesting {
  harvesting_method: 'Machine Based Harvesting' | 'Human Based Harvesting'
  cost: number
}

export interface ShellingAndThreshing {
  shelling_threshing_method: 'Machinery' | 'Labour'
  cost: number
}

export interface Packaging {
  packaging_method: string
  cost: number
}

export interface Transportation {
  cost: number
}

export interface LandPreparation {
  tillage_method: 'Minimum' | 'Conventional' | 'No Till'
  harrowing: boolean
  cost: number
}

export interface ControlServices {
  service_id: number | 302 | 303
  service_cost: number
}

export interface WeedingChemicals {
  chemical_id: number
  chemical_price_detail_id: number
  chemical_cost: number
  weed_id: number
  chemical_schedule: string
  adjuvant_price_detail_id: number
  adjuvant: string
  adjuvant_cost: number
}

export interface PestChemicals {
  chemical_id: number
  chemical_price_detail_id: number
  chemical_cost: number
  chemical_schedule: string
  chemical_type: 'fungicide' | 'pesticide'
  adjuvant_price_detail_id: number
  adjuvant: string
  adjuvant_cost: number
  disease_id: number | null | undefined
  pest_id: number | null | undefined
}

export interface PestControl {
  type: 'Chemical' | 'Manual'
  cp_pest_disease_control_id: number
  cp_id: number
  total_chemical_cost: number
  total_application_cost: number
  chemicals: PestChemicals[] | null
  control_services: ControlServices[]
}

export interface WeedControl {
  type: 'Chemical' | 'Manual'
  cp_weed_control_id: number
  cp_id: number
  chemicals: WeedingChemicals[] | null
  total_application_cost: number | string
  total_chemical_cost: number | string
  control_services: ControlServices[]
}

export interface Grant {
  ga_id: number
  application_date: Date
  farmer_id: number
  farm_id: number
  lease_id: number
  firstname: string
  mid_name: null
  surname: string
  omang: number
  contact: number
  available_size: number
  plough_season: string
  total_costs: number
  grant_tracking_no: string
}

export interface GrantCroppingPlan {
  ga_id: number
  cp_id: number
  hectarage: number
  plough_season: string
  crop_name: string
}

export interface SoilSampleRequest {
  comment: string
  ssr_id: number
  status_name: string
  farm_id: number
  farm_name: string
  merchant_id: number
  merchant_name: string
  location: string
  farmer_id: number
  create_date: Date
  farmer_firstname: string
  farmer_middlename: string
  farmer_surname: string
  farmer_contact: string
  plough_season: string
  eo_firstname: string
  eo_middlename: string | null
  eo_surname: string
  user_id: number
  company_name: string | null
  company_reg_no: string | null
  farmer_type: number
}

export interface SoilSampleAnalysis {
  ssr_id: number
  bulk_density: string
  ec_comp: string
  fe_comp: string
  mn_comp: string
  cu_comp: string
  ph_comp: string
  ca_comp: string
  zn_comp: string
  s_comp: string
  mg_comp: string
  na_comp: string
  k_comp: string
  p_comp: string
  c_comp: string
  farmer_id: number
  farm_id: number
  first_name: string
  mid_name: string | null
  surname: string
  contact: string
  email: string
  plough_season: string
  expiry_date: Date
  merchant_id: number
  eo_firstname: string
  eo_middlename: string
  eo_surname: string
  village_name: string
  farm_name: string
  laboratory_name: string
  cec_comp: string
}

export interface Inventory {
  farm_id: number
  farmer_id: number
  acceptance_status: 'accepted' | 'rejected' | 'pending acceptance'
  verification_status: 'verified' | 'pending verification' | 'rejected'
  farm_name: string
  inventory_type: string
  inventory_model: string
}

export interface InventoryItem {
  farm_id: number
  farmer_id: number
  inventory_id: number
  last_updated_by: number
  hours_used: number
  last_update_date: string
  create_date: string
  resource_item: number
  quantity: number
  item_status: string
  item_verification_status: string
  yom: string
  reg_number: string
}

export interface LoanHeader {
  loan_application_id: number
  farmer_id: number
  marital_status: string
  create_date: string
  last_update_date: string
  last_update_by: number
  loan_requirement1: number
  loan_requirement2: number
  loan_requirement3: string
  loan_requirement4: string
  status_id: number
  period_at_physical_address: number
  period_at_prev_physical_address: number
  ownership_at_physical_address: string
  source_reference: string
  title: string
  firstname: string
  mid_name: string
  surname: string
  omang: number
  contact: number
}

export interface Transaction {
  transaction_id: number
  date: Date
  seed_list: Details[]
  service_list: Details[]
  firstname: string
  surname: string
  amount: number
}

export interface FarmerTransaction {
  transaction_id: number
  date: string
  merchant_type_id: number
  merchant_name: string
  seed_list: Details[]
  service_list: Details[]
  voucher_avail_balance: number
  voucher_id: number
  voucher_amount: number
  voucher_expensed_amount: number
  voucher_expiry_date: string
  farmer_id: number
  amount: number
}

export interface FarmerVoucherTransaction {
  transaction_id: number
  date: Date | string
  amount: number
  voucher_id: number
  voucher_amount: number
  voucher_expensed_amount: number
  voucher_avail_balance: number
  voucher_expiry_date: Date | string
  farmer_id: number
  merchant_type_id: number
  merchant_name: string
  pesticide_list: Details[] | null
  herbicide_list: Details[] | null
  fungicide_list: Details[] | null
  adjuvant_list: Details[] | null
  service_list: Details[] | null
  fertilizer_npks_list: Details[] | null
  fertilizer_non_npks_list: Details[] | null
  seed_list: Details[] | null
}

export interface Details {
  id: number
  qty: number
  price: number
  total: number
}

export interface SelectedObject {
  id: number
  quantity?: number
  price?: number
  merchant_price?: number
}

export interface VoucherObject {
  merchant_id: number
  omang: string
  seeds?: SelectedObject[]
  services?: SelectedObject[]
}

export interface SeedDealerFilter {
  crop_id: number
  crop_type: 'HYBRID' | 'OPV'
}

export interface ServiceProviderFilter {
  tillage_method: 'Minimum' | 'Conventional' | 'No Till'
  is_harrowing: boolean
  chemical_weed_control: boolean
  manual_weed_control: boolean
  pest_control: boolean
}

export interface SeedDealerGrantCroppingPlanData {
  crop_id: number
  crop_name: string
  crop_type: 'HYBRID' | 'OPV'
}

export interface Chemical {
  chemical_price_detail_id: number
  weed_id?: number
  chemical: string
  adjuvant: string
  weed_name?: string
  adjuvant_price_detail_id: number
  chemical_schedule?: string
  chemical_type: string
  pest_id?: number
  pest_name?: string
}

export interface ChemicalDealerCroppingPlanData extends SeedDealerGrantCroppingPlanData {
  chemicals: Chemical[]
}

export interface ServiceProviderGrantCroppingPlanData {
  hectarage: number
  tillage_method: 'Minimum' | 'Conventional' | 'No Till'
  is_harrowing: boolean
  chemical_weed_control: boolean
  manual_weed_control: boolean
  pest_control: boolean
}

export interface IsVerifiedDTO {
  exists: boolean
  total_hectarage: number
  grant_cropping_plans_data:
    | ServiceProviderGrantCroppingPlanData[]
    | SeedDealerGrantCroppingPlanData[]
}

export interface VoucherContactDTO {
  contact: string
}

export interface PestControlChemicals {
  chemical_id: number
  chemical_price_detail_id: number
  chemical_reg_no: string | null
  chemical_type: 'fungicide' | 'pesticide' | 'insecticide'
  chemical_cost: number
  adjuvant_price_detail_id: number | null
  pest_id: number | null
  pest_name: string | null
  adjuvant_reg_no: string | null
  adjuvant_cost: number | null
}

export interface WeedingReviewChemicals {
  chemical_id: number
  chemical_price_detail_id: number
  chemical_reg_no: string | null
  chemical_type: 'fungicide' | 'pesticide' | 'insecticide'
  chemical_cost: number
  adjuvant_price_detail_id: number | null
  weed_id: number | null
  weed_name: string | null
  adjuvant_reg_no: string | null
  adjuvant_cost: number | null
  chemical_schedule: 'Post Emergence' | 'Pre Planting' | 'Pre Emergence'
}

export interface CroppingPlanDetails {
  id: number
  plough_season: number
  farmer_id: number
  farm_id: number
  hectarage: number
  ssr_id: number
  comments: string | null
  isVerified: CroppingPlanHeaderStatus
  crop_id: number
  seed_detail_id: number | null
  seed_type: string | null
  seed_variety: string | null
  seed_cost: number | null
  anticipated_returns: number | null
  target_yield: number | null

  weed_control_application: null
  weed_control_application_cost: number | null
  weed_control_total_cost: number
  weed_control_chemical_cost: number | null
  weed_control_chemical_method: WeedingChemicalMethods | null
  weed_control_chemical_schedule: WeedAndPestApplicationStage | null
  weed_control_chemicals: ChemicalListWithID[] | null
  weed_control_manual_method: ManualWeedMethods | null
  weed_control_manual_schedule: null
  weed_control_type: string | null

  pest_control_application: string | null
  pest_control_application_cost: number | null
  pest_disease_control_total_cost: number
  pest_disease_application_cost: number
  pest_disease_control_chemical_cost: number
  pest_control_chemical_cost: number | null
  pest_control_chemical_method: PestChemicalMethods | null
  pest_control_chemical_schedule: 'Contact' | 'Preventive' | null
  pest_control_chemicals: ChemicalListWithID[] | null
  pest_control_manual_method: 'Physical Pest Control' | 'Biological Pest Control' | null
  pest_control_manual_schedule: PestManualSchedule | null
  pest_disease_control: PestControlChemicals[] | null
  pest_control_type: string | null
  weed_control: WeedingReviewChemicals[] | null

  basal_fertilizers: []
  top_fertilizers: []
  top_fertilizer_application: null
  fertilizer_application_cost: null
  fertilizer_cost: null
  total_fertilizer_cost: null

  harvesting_method: string | null
  harvesting_cost: number | null
  shelling_threshing_method: string | null
  shelling_threshing_cost: number | null
  packaging_method: string | null
  packaging_cost: number | null
  isHarrowing: boolean | null
  tillage_method: string | null
  land_preparation_cost: number | null
  transportation_cost: number | null
}

export interface ServicesList {
  SERVICE_HEADER_ID: number
  SERVICE_NAME: string
  SERVICE_DESC: string
  SERVICE_TYPE: string
  PLOUGH_SEASON: string
  PRICE: number
  UNIT_OF_MEASURE: string
  UOM_COUNT: number
  quantity?: number
  merchant_price?: number
}

export interface CompanyDetails {
  co_uin: string
  co_name: string
  co_reg_date: Date
  co_postal_address: string
  co_physical_address: string
  co_contact: string
  co_email: string
  shareholder: string[]
  active_status: string
}

export interface DirectorDetails {
  director_name: string
  id: string
  director_contact_number: string
}

export interface ShareHoldersDetails {
  citizen: 'Y' | 'N'
  surname: string
  firstnames: string
  id: string | null | undefined
  passport: string | null
  place_of_birth: string
  date_of_birth: Date | string
  id_expiry_date: Date | string | null
  passport_expiry_date: Date | string | null
  gender: string
  contact: string
}

export interface ShareHoldersDetailsResponse {
  isShareholder: boolean
}

export interface FarmerContact {
  contact: string
}
