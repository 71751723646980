import type React from 'react'
import { useSuperTokens } from '../../features/authentication/hooks/useSuperTokens'
import { Button, Text } from '../ui'

export const NoAccount: React.FC = () => {
  const { logOut } = useSuperTokens()

  return (
    <main className={'flex flex-col gap-4 h-screen justify-center items-center'} role='alert'>
      <div className={'text-center'}>
        <Text size={'large'} variant={'error'} className={'leading-[200%]'}>
          No accounts found
        </Text>
        <Text className={'text-center'} size={'medium'}>
          Sorry no accounts with the provided information were found in the system. Please try
          registering again.
        </Text>
      </div>
      <Button onClick={logOut} size={'sm'}>
        Try again
      </Button>
    </main>
  )
}
