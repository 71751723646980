import { ErrorPage } from 'components/Errors/ErrorPage'
import type React from 'react'
import { useState } from 'react'
import { Button, Card, Dialog, DialogContent, Loader, Text } from 'components/ui'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { Box, TabView } from '../../../../components/App/Layout'
import { useGetMOInventories } from '../api/getInventories'
import { useOfficerStore } from '../../../../stores/useOfficerStore'
import { MOInventoriesTable } from '../components/tables/MOInventoriesTable'
import { SearchInventories } from './SearchInventories'
import { uniqBy } from 'lodash'
import { viewInventoryColumns } from '../components/tables/viewInventoryColumn'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { NotAvailable } from '../../../../components/Layout/NotAvailable'

export const InventoriesMO: React.FC = () => {
  const [newRequest, setNewRequest] = useState(false)
  const { officer } = useOfficerStore()
  const { t } = useTranslation('inventory')
  const { data: inventories, isLoading, error } = useGetMOInventories(officer?.district_id)
  const [dialogType, setDialogType] = useState<string>()
  const flags = useFlags()
  const handleCloseNewRequest = (): void => {
    setNewRequest(false)
  }

  const handleOpenNewRequest = (type: string): void => {
    setNewRequest(true)
    setDialogType(type)
  }

  if (isLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }
  if (flags.inventory === false) {
    return <NotAvailable />
  }
  if (error != null && error.response?.status !== 404) {
    return <ErrorPage />
  }

  const farmerInventories = inventories?.data?.filter(
    (item) => item.farmer_id != null && item.item_verification_status !== 'PENDING_VERIFICATION'
  )

  const merchantInventories = inventories?.data?.filter(
    (item) => item.merchant_id != null && item.item_verification_status !== 'PENDING_VERIFICATION'
  )

  const farmerColumns = viewInventoryColumns(farmerInventories ?? [], t)
  const merchantColumns = viewInventoryColumns(merchantInventories ?? [], t)
  return (
    <Box>
      <TabView>
        <Tabs defaultValue='FI' className='flex flex-col gap-4 w-full'>
          <div
            className={'flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center w-full'}
          >
            <TabsList
              className={`grid w-full sm:w-2/3 md:w-1/2 lg:w-2/5 ${
                flags.merchantInventory ? 'grid-cols-2' : 'grid-cols-1'
              }`}
            >
              <TabsTrigger value='FI'>{t('mech.farmerI')}</TabsTrigger>
              {flags.merchantInventory === true && (
                <TabsTrigger value='MI'>{t('mech.mi')}</TabsTrigger>
              )}
            </TabsList>
            <div className={'flex items-center gap-4'}>
              {flags.inventoryTransfer && (
                <Button
                  size={'xs'}
                  variant={'warning'}
                  onClick={() => {
                    handleOpenNewRequest('Transfer')
                  }}
                >
                  {t('mech.vt')}
                  <p className={'fa-solid fa-check'} />
                </Button>
              )}
              <Button
                size={'xs'}
                variant={'success'}
                onClick={() => {
                  handleOpenNewRequest('Assess')
                }}
              >
                {t('mech.ai')}
                <p className={'fa-solid fa-add'} />
              </Button>
            </div>
          </div>

          <div>
            {/* Farmer Inventories Tab */}
            <TabsContent value='FI'>
              {farmerInventories?.length !== 0 ? (
                <Card
                  className={
                    'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
                  }
                >
                  <MOInventoriesTable
                    columns={farmerColumns}
                    data={uniqBy(farmerInventories, 'farmer_id')}
                  />
                </Card>
              ) : (
                <div className='w-full flex flex-col justify-center items-center bg-white gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
                  <Text size={'medium'} variant={'bodyTextLight'}>
                    {t('mech.nif')}
                  </Text>
                </div>
              )}
            </TabsContent>

            {/* Merchant Inventories Tab */}
            {flags.merchantInventory === true && (
              <TabsContent value='MI'>
                {merchantInventories?.length !== 0 ? (
                  <Card
                    className={
                      'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
                    }
                  >
                    <MOInventoriesTable
                      columns={merchantColumns}
                      data={uniqBy(merchantInventories, 'merchant_id')}
                    />
                  </Card>
                ) : (
                  <div className='w-full flex flex-col justify-center items-center bg-white gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
                    <Text size={'medium'} variant={'bodyTextLight'}>
                      {t('mech.nif')}
                    </Text>
                  </div>
                )}
              </TabsContent>
            )}
          </div>
        </Tabs>

        {/* Dialog for new request */}
        <Dialog open={newRequest} defaultOpen={false} modal onOpenChange={handleCloseNewRequest}>
          <DialogContent
            className={'bg-card flex flex-col items-center text-center gap-4 md:gap-8'}
          >
            <Text size={'medium'}>{dialogType === 'Transfer' ? t('mech.vtd') : t('mech.spi')}</Text>
            <SearchInventories dialogType={dialogType} />
          </DialogContent>
        </Dialog>
      </TabView>
    </Box>
  )
}
