import type React from 'react'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import Spinner from 'components/ui/spinner'
import { Outlet } from 'react-router-dom'

import { Button, Text } from 'components/ui'

export const NotFound: React.FC = () => {
  const claimValue = Session.useClaimValue(UserRoleClaim)

  if (claimValue.loading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }
  return (
    <div className='flex flex-col items-center justify-center w-full h-full'>
      <Text size={'xxLarge'} className={'text-[clamp(4rem,15vw,10rem)]'} variant={'error'}>
        404
      </Text>
      <Text size={'medium'}>Page you are looking does not exist!</Text>
      <div className='w-full max-w-[400px] flex justify-center space-x-4 mt-8'>
        <Button
          variant='secondary'
          size='lg'
          onClick={() => {
            window.history.back()
          }}
        >
          Back
        </Button>
        <Button variant='primary' size='lg' onClick={() => (window.location.href = '/')}>
          Home
        </Button>
      </div>
      <Outlet />
    </div>
  )
}
