import { type FC, type ReactNode } from 'react'
import { Text } from 'components/ui'

export const TitleValue: FC<{ title: string; value: string; children?: ReactNode }> = ({
  title,
  value,
  children
}) => {
  return (
    <div className={'flex items-center gap-4'}>
      <div className={'flex items-center gap-4'}>
        <Text size={'small'} variant={'bodyTextLight'}>
          {title}
        </Text>
        <Text className={'capitalize'}>{value}</Text>
      </div>
      {children}
    </div>
  )
}
