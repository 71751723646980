import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorPage } from '../Errors/ErrorPage'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import { useAuthenticationStore } from '../../stores/useAuthenticationStore'
import Session, { SessionAuth } from 'supertokens-auth-react/recipe/session'
import Spinner from 'components/ui/spinner'
import { useMerchantStore } from '../../stores/useMerchantStore'
import { useGetMerchant } from '../../features/authentication/api/getMerchant'
import { getRole } from 'utils/utilFunctions'
import { Unauthorized } from '../Errors/Unauthorized'
import { Navigate, useNavigate } from 'react-router-dom'
import { useStaticDataStore } from '../../stores/useStaticDataStore'
import { usePloughSeason } from '../../hooks/usePloughSeason'
import { NoAccount } from '../Errors/NoAccount'

const AgroChemicalDealersDashboardLayout = React.lazy(
  async () => import('../Dashboard/AgroChemicalDealers')
)

export const AgroChemicalDealers: React.FC = () => {
  const navigate = useNavigate()
  const sessionContext = Session.useSessionContext()
  const { setRole, isLogout } = useAuthenticationStore()
  const { setMerchant } = useMerchantStore()

  const claimValue = Session.useClaimValue(UserRoleClaim)
  const {
    data: merchantData,
    isInitialLoading: loadingMerchant,
    error: merchantError
  } = useGetMerchant(
    sessionContext.loading ? null : sessionContext.userId,
    claimValue.loading ? null : getRole(claimValue.value)
  )
  const { setStaticData } = useStaticDataStore()
  const { isLoading, agroData } = usePloughSeason(2003)
  useEffect(() => {
    if (merchantData != null) {
      setRole(claimValue.loading ? null : getRole(claimValue.value))
      setMerchant(merchantData?.data[0])
    }
    if (agroData != null) {
      setStaticData(undefined, agroData, undefined)
    }
  }, [merchantData, agroData])

  if (sessionContext.loading || claimValue.loading || loadingMerchant || isLoading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }

  if (merchantError != null) {
    if (merchantError.status === 404) {
      return <NoAccount />
    }
    return <ErrorPage />
  }

  if (!sessionContext.doesSessionExist && (isLogout === false || isLogout === null)) {
    return <Navigate to='/session'></Navigate>
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <SessionAuth
        accessDeniedScreen={Unauthorized}
        onSessionExpired={() => {
          navigate('/session')
        }}
        overrideGlobalClaimValidators={(globalValidators) => [
          ...globalValidators,
          UserRoleClaim.validators.includes('AGRO_CHEMICAL_DEALER')
        ]}
      >
        <AgroChemicalDealersDashboardLayout />
      </SessionAuth>
    </ErrorBoundary>
  )
}
