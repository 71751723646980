import type React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useOfficerStore } from 'stores/useOfficerStore'
import { ErrorPage } from 'components/Errors/ErrorPage'
import { Button, Card, Dialog, DialogContent, Loader, Text } from 'components/ui'
import { TabView } from '../../../../components/App/Layout'
import { SearchBar } from '../../../../components/Form/SearchBar'
import { useGetCroppingPlanByExt } from '../api/getCroppingPlanByExt'
import { LabCroppingPlansTable } from '../components/tables/LabCroppingPlansTable'
import { labCroppingPlansColumns } from '../components/tables/lab-cropping-plans-columns'
import { useTranslation } from 'react-i18next'
import { FolderX } from 'lucide-react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { ComingSoon } from '../../../../components/Layout/ComingSoon'

export const AllCroppingPlans: React.FC = () => {
  const flags = useFlags()
  const navigate = useNavigate()
  const { t } = useTranslation('extensionOfficer')
  const ldClient = useLDClient()
  const { officer } = useOfficerStore()
  const [newRequest, setNewRequest] = useState(false)
  const {
    data: croppingPlans,
    isLoading,
    error
  } = useGetCroppingPlanByExt(officer?.ext_area_id?.toString() ?? '')

  const [searchParams] = useSearchParams()
  const query = searchParams.get('q')
  const [searchQuery, setSearchQuery] = useState(query ?? '')
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value)
  }

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (flags.croppingPlanOfficer === false) {
    return <ComingSoon />
  }

  if (isLoading) {
    return (
      <div className='grid place-items-center min-h-[30%] h-full flex-1'>
        <Loader />
      </div>
    )
  }

  if (error != null && error?.response?.status !== 404) {
    return <ErrorPage />
  }

  const handleCloseNewRequest = (): void => {
    setNewRequest(false)
  }

  const handleOpenNewRequest = (): void => {
    setNewRequest(true)
  }

  if (croppingPlans?.data === undefined) {
    return (
      <TabView>
        <div className='flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center w-full'>
          <Text size='large'>{t('cp.myCP')}</Text>
          <Button size='xs' variant='success' onClick={handleOpenNewRequest}>
            {t('cp.vCP')}
            <p className='fa-solid fa-add' />
          </Button>
        </div>

        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <FolderX />
          </Text>
          <Text size={'medium'} variant={'bodyTextLight'}>
            {t('cp.noCP')}
          </Text>
        </div>

        <Dialog open={newRequest} defaultOpen={false} modal onOpenChange={handleCloseNewRequest}>
          <DialogContent className='bg-card w-full md:w-[50%] lg:w-[35%] max-w-[430px]'>
            <Text size='medium'>{t('cp.vCP')}</Text>
            <SearchBar
              label={t('cp.searchFID')}
              className='w-full max-w-[700px] flex gap-8'
              searchQuery={searchQuery}
              disabled={searchQuery.length !== 7}
              onChange={handleChange}
              onSearch={() => {
                navigate(`/officer/verify-cp/${searchQuery ?? ''} `)
              }}
              isSearching={isLoading}
            />
          </DialogContent>
        </Dialog>
      </TabView>
    )
  }

  return (
    <TabView>
      <div className='flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center w-full'>
        <Text size='large'>{t('cp.myCP')}</Text>
        <Button size='xs' variant='ghost' onClick={handleOpenNewRequest}>
          {t('cp.vCP')}
        </Button>
      </div>

      <Card className='w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
        <LabCroppingPlansTable
          columns={labCroppingPlansColumns(t)}
          data={croppingPlans.data.filter(
            (item) => item.is_verified !== 'Submitted CP Verification'
          )}
        />
      </Card>

      <Dialog open={newRequest} defaultOpen={false} modal onOpenChange={handleCloseNewRequest}>
        <DialogContent className='bg-card w-full md:w-[50%] lg:w-[35%] max-w-[430px]'>
          <Text size='medium'>{t('cp.vCP')}</Text>
          <SearchBar
            label={t('cp.searchFID')}
            className='w-full max-w-[700px] flex gap-8'
            searchQuery={searchQuery}
            disabled={searchQuery.length !== 7}
            onChange={handleChange}
            onSearch={() => {
              navigate(`/officer/verify-cp/${searchQuery ?? ''} `)
            }}
            isSearching={isLoading}
          />
        </DialogContent>
      </Dialog>
    </TabView>
  )
}
