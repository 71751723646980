import type React from 'react'
import { useEffect } from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type Farm, type Lease } from 'types'
import { useGetHeader } from '../api/header/getHeader'
import { Button, Loader, Text } from 'components/ui'
import { CircleOff, Info } from 'lucide-react'
import { CroppingPlansTable } from './tables/CroppingPlansTable'
import { croppingPlansColumns } from './tables/cropping-plans-columns'
import { AxiosError } from 'axios'
import { Error } from 'components/Errors/Error'
import { useTranslation } from 'react-i18next'
import { usePloughSeason } from '../../../../hooks/usePloughSeason'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

interface Base {
  openCroppingPlan: () => void
  openApprovedCroppingPlan: () => void
  openReturnedCroppingPlan: () => void
  newCroppingPlan: () => void
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type CroppingPlansProps = WithFarm | WithLease

export const CroppingPlans: React.FC<CroppingPlansProps> = ({
  farm,
  lease,
  openCroppingPlan,
  openApprovedCroppingPlan,
  openReturnedCroppingPlan,
  newCroppingPlan
}) => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const { farmer } = useFarmerStore()
  const { isCurrentSeason } = usePloughSeason()
  const {
    data: croppingPlans,
    isLoading,
    error
  } = useGetHeader(farmer?.farmer_id, farm?.farm_id !== undefined ? farm.farm_id : lease?.farm_id)
  const { t } = useTranslation('landManagement')

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (isLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    if (error instanceof AxiosError) {
      if (error?.response?.status !== 404) {
        return <Error />
      }
    }
  }

  if (croppingPlans != null) {
    return (
      <>
        <div className={'flex justify-between items-center'}>
          <Text size={'medium'}>{t('cp.cps')}</Text>
          <Button
            disabled={flags.cpCreation === false}
            variant={'success'}
            size={'xs'}
            onClick={newCroppingPlan}
          >
            {t('cp.new')}
            <p className='fa-solid fa-right-left' />
          </Button>
        </div>

        <div className='flex'>
          <Info size='18' color='red' className='mr-2.5' />
          <Text variant='error' size='small' className=''>
            N.B. The total hectarage of your cropping plans per season cannot exceed your available
            land size.
          </Text>
        </div>
        <CroppingPlansTable
          data={croppingPlans.data}
          columns={croppingPlansColumns(t)}
          onApproved={openApprovedCroppingPlan}
          onReturned={openReturnedCroppingPlan}
          onDraft={openCroppingPlan}
          isCurrentSeason={isCurrentSeason}
          isDraftDisabled={flags.cpCreation === false}
        />
      </>
    )
  }

  // no cropping plans available
  return (
    <>
      <div className={'flex justify-between items-center'}>
        <Text size={'medium'}>{t('cp.cps')}</Text>
        <Button
          disabled={flags.cpCreation === false}
          variant={'success'}
          size={'xs'}
          onClick={newCroppingPlan}
        >
          {t('cp.new')}
          <p className='fa-solid fa-right-left' />
        </Button>
      </div>
      <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
        <Text size={'xLarge'} className={'opacity-40'}>
          <CircleOff />
        </Text>
        <Text size={'medium'} variant={'bodyTextLight'}>
          No Cropping Plans Available
        </Text>
      </div>
    </>
  )
}
