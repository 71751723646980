import { FolderX } from 'lucide-react'
import { Grow } from '@mui/material'
import { useMyFarms } from 'features/farmer/land-management/hooks/useMyFarms'
import type React from 'react'
import { useState } from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { FarmCard } from '../components/generic/FarmCard'
import { FilterMenu } from '../components/generic/filterMenu'
import { RegistrationContainer } from '../components/new-farm/RegistrationContainer'
import { Box } from 'components/App/Layout'
import { Button, Dialog, DialogContent, Text, Tooltip, TooltipContent, TooltipTrigger } from 'components/ui'
import { Skeleton } from 'components/ui/skeleton'
import { SkeletonCard } from 'components/SkeletonCardLoader'
import { Error } from 'components/Errors/Error'
import { useTranslation } from 'react-i18next'

export const LandManagement: React.FC = () => {
  const [newFarm, setNewFarm] = useState(false)
  const [filter, setFilter] = useState<'all' | 'verified' | 'rejected' | 'pending'>('all')
  const { farmer } = useFarmerStore()
  const { pending, verified, farms, isLoading, rejected, error } = useMyFarms(farmer?.farmer_id)
  const { t } = useTranslation('landManagement')

  const handleCloseNewFarm = (): void => {
    setNewFarm(false)
  }

  const handleOpenNewFarm = (): void => {
    setNewFarm(true)
  }

  if (isLoading) {
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <div className={'flex justify-between'}>
          <Skeleton className='w-[75px] md:w-[150px] h-[20px] rounded-full' />
          <Skeleton className='w-[75px] md:w-[150px] h-[20px] rounded-full' />
        </div>
        <Skeleton className='w-[250px] md:w-[400px] h-[20px] rounded-full' />
        <div
          className={
            'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none py-4'
          }
        >
          {Array(16)
            .fill(1)
            .map((_, index) => {
              return <SkeletonCard key={index} />
            })}
        </div>
      </div>
    )
  }

  if (error != null && error?.response?.status !== 404) {
    return (
      <Box>
        <Error />
      </Box>
    )
  }

  return (
    <Box>
      <div className={'flex justify-between items-center'}>
        <Text size={'large'}>{t('homePage.myFarms')}</Text>
        <Tooltip>
          <TooltipTrigger>
            <Button size={'xs'} variant={'success'} onClick={handleOpenNewFarm}>
              {t('homePage.register')}
              <p className={'fa-solid fa-add'} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Add new farm</p>
          </TooltipContent>
        </Tooltip>
      </div>

      {farms == null ? (
        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <FolderX />
          </Text>
          <Text size={'medium'} variant={'bodyTextLight'}>
            {t('homePage.noFarms')}
          </Text>
        </div>
      ) : (
        <div className={'w-full flex flex-col gap-4'}>
          <FilterMenu
            all={farms?.length ?? 0}
            verified={verified?.length ?? 0}
            rejected={rejected?.length ?? 0}
            pending={pending?.length ?? 0}
            filterBy={filter}
            setFilterBy={setFilter}
          />
          <Grow in={farms != null}>
            <div
              className={
                'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none py-4'
              }
            >
              {farms
                .filter((farm) => {
                  if (filter === 'all') {
                    return farm
                  }
                  return farm.status === filter
                })
                .map((farm) => {
                  return <FarmCard farm={farm} key={farm.farm_id} />
                })}
            </div>
          </Grow>
        </div>
      )}

      <Dialog open={newFarm} defaultOpen={false} modal={true} onOpenChange={handleCloseNewFarm}>
        <DialogContent
          className={
            'bg-card w-[90vw] md:w-[50%] lg:w-[35%] max-w-[430px] max-h-[80vh] overflow-y-auto'
          }
        >
          <RegistrationContainer onClose={handleCloseNewFarm} farmer={farmer!} />
        </DialogContent>
      </Dialog>
    </Box>
  )
}
