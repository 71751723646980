import type React from 'react'
import { type Lease } from 'types'
import { Badge, Button, Card, DropdownMenu, DropdownMenuTrigger, Text } from 'components/ui'
import dayjs from 'dayjs'
import { MoreVertical } from 'lucide-react'
import { AcceptOrRejectLease } from './AcceptOrRejectLease'
import { useTranslation } from 'react-i18next'
import { TitleValue } from '../lease-details/TitleValue'

interface LeaseOfferCardProps {
  lease: Lease
}

export const LeaseOfferCard: React.FC<LeaseOfferCardProps> = ({ lease }) => {
  // green for lease offer
  // red for termination
  const borderColor =
    lease.status_desc === 'INITIATED BY LESSOR' ? 'border-success' : 'border-danger'

  const { t } = useTranslation('leaseManagement')
  return (
    <Card
      className={`md:max-w-[500px] relative flex flex-col gap-2 sm:gap-4 md:px-6 lg:px-8 transition-shadow duration-200 ease-in-out transform ${borderColor}`}
    >
      <div className={'flex items-start justify-between relative'}>
        <Text
          size={'medium'}
          variant={lease.status_desc === 'INITIATED BY LESSOR' ? 'success' : 'error'}
        >
          {lease.status_desc === 'INITIATED BY LESSOR' ? t('leaseForm.offer') : t('leaseForm.ltr')}
        </Text>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size={'icon'} variant='icon' className='h-8 w-8 p-0'>
              <span className='sr-only'>Open menu</span>
              <MoreVertical className='h-5 w-5' />
            </Button>
          </DropdownMenuTrigger>
          <AcceptOrRejectLease lease={lease} />
        </DropdownMenu>
      </div>

      <div className={'w-full flex flex-col gap-4'}>
        <TitleValue
          title={`${t('leaseForm.from')}`}
          value={
            lease.origin_farmer_type === 2
              ? lease.origin_farmer_company_name?.toLowerCase() ?? 'N/A'
              : lease.origin_farmer_name.toLowerCase()
          }
        />
        <TitleValue
          title={`${t('leaseForm.name')}`}
          value={lease.farm_name.toLowerCase()}
          children={<Badge>{lease.leased_size} ha</Badge>}
        />
        <TitleValue
          title={`${t('leaseForm.village')}`}
          value={lease.extension_area.toLowerCase() + ', ' + lease.village.toLowerCase()}
        />
      </div>

      <div
        className={
          'bg-primary-background-dark dark:bg-primary-background flex justify-between items-center rounded p-2'
        }
      >
        <div className={'flex flex-col'}>
          <Text size={'small'} variant={'bodyTextLight'}>
            {t('leaseForm.leaseFrom')}
          </Text>
          <Text>{dayjs(lease.commencement_date).format('DD-MM-YYYY')}</Text>
        </div>
        <div className={'flex flex-col'}>
          <Text size={'small'} variant={'bodyTextLight'}>
            {t('leaseForm.expiry')}
          </Text>
          <Text>
            {/* If termination date is not empty use a default expiry date */}
            {lease.new_termination_date != null
              ? dayjs(lease.new_termination_date).format('DD-MM-YYYY')
              : dayjs(lease.expiry_date).format('DD-MM-YYYY')}
          </Text>
        </div>
      </div>
    </Card>
  )
}
