import { type FC, type ReactNode } from 'react'

interface EligibilityContainerInterface {
  reasons?: ReactNode
  children?: ReactNode
}
export const EligibilityContainer: FC<EligibilityContainerInterface> = ({ reasons, children }) => {
  return (
    <div className='w-full flex flex-col items-center'>
      <div className='w-full flex flex-col h-full'>
        {reasons}
        <div className='mt-4 w-full flex justify-between'>{children}</div>
      </div>
    </div>
  )
}
