import { LoanCard } from 'features/loan-management/components'
import { ModalStepper } from 'features/loan-management/components/generic/modalSteps/ModalStepper'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useFarmerStore } from '../../../stores/useFarmerStore'
import { useGetLoanHeader } from '../api/getLoanHeader'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Loader,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'components/ui'
import { Box } from '../../../components/App/Layout'
import { useTranslation } from 'react-i18next'
import { ComingSoon } from '../../../components/Layout/ComingSoon'

export const LoanView: React.FC = () => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const { t } = useTranslation('loanManagement')

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  const [newLoan, setNewLoan] = useState(false)
  const handleCloseNewLoan = (): void => {
    setNewLoan(false)
  }
  const { farmer } = useFarmerStore()
  const { data, isLoading } = useGetLoanHeader(farmer?.farmer_id as number)

  const handleOpenNewLoan = (): void => {
    setNewLoan(true)
  }

  if (flags.loan === false || farmer?.class_id !== 'TAXPAYER') {
    return <ComingSoon />
  }

  if (isLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (data?.data === undefined) {
    return (
      <Box>
        <div className={'flex justify-between items-center'}>
          <Text size='large'>{t('home.my')}</Text>

          <Tooltip>
            <TooltipTrigger>
              <Button size={'xs'} variant={'warning'} onClick={handleOpenNewLoan}>
                {t('home.new')}
                <p className={'fa-solid fa-add'} />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Apply for a new loan</p>
            </TooltipContent>
          </Tooltip>
        </div>
        <Card className='w-full flex flex-col bg-card gap-4 md:gap-6 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
          <Text className={' flex justify-center items-center'} size={'medium'}>
            {t('home.no')}
          </Text>
          <Text className={' flex justify-center items-center'} size={'body'}>
            {t('home.apply')}
          </Text>
        </Card>
        <Dialog open={newLoan} defaultOpen={false} modal onOpenChange={handleCloseNewLoan}>
          <DialogContent className='w-[90vw] max-w-[500px] h-auto'>
            <ModalStepper onClose={handleCloseNewLoan} />
          </DialogContent>
        </Dialog>
      </Box>
    )
  }

  // Complete once loan application is processed
  return (
    <div className={'w-full flex flex-col gap-4 p-4'}>
      <div className={'w-full flex justify-between'}>
        <h2 className={'text-[color:var(--color-secondary-light)] text-lg md:text-2xl truncate '}>
          {t('home.loan')}
        </h2>
      </div>

      <>
        <div
          className={
            'flex flex-col sm:flex-row items-end sm:items-start justify-between gap-2 sm:gap-8'
          }
        >
          <LoanCard loanHeader={data?.data} isLoading={isLoading} />
        </div>
      </>
    </div>
  )
}
