import type React from 'react'
import { type ReactElement, useState } from 'react'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTrigger,
  Loader,
  Text, Tooltip, TooltipContent, TooltipTrigger
} from '../../../../components/ui'
import { useSearchParams } from 'react-router-dom'
import { NotEligible } from '../../components/NotEligible'
import { NotFound } from '../../../../components/Errors/NotFound'
import { ErrorPage } from '../../../../components/Errors/ErrorPage'
import { useGetVoucherEligibility } from '../../api/getVoucherEligibility'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'
import { useTranslation } from 'react-i18next'
import { SeedsTable } from '../../seed-dealer/components/SeedsTable'
import { seedsColumns } from '../../seed-dealer/components/columns'
import { find } from 'lodash'
import { ChemicalsTable } from '../../chemical-dealer/components/ChemicalsTable'
import { chemicalsColumns } from '../../chemical-dealer/components/chemicals-columns'
import {
  type ChemicalDealerCroppingPlanData,
  type SeedDealerGrantCroppingPlanData
} from '../../../../types'
import { type Seeds } from '../../../../types/static'
import { filteredChemicals } from '../../utils/filteredchemicals'
import { usePloughSeason } from '../../../../hooks/usePloughSeason'
import { type ChemicalData } from '../../chemical-dealer/types'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const ChemicalSeedsVoucherRedemption: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('merchant')
  const flags = useFlags()
  const query = searchParams.get('omang')
  const { data, isLoading, error } = useGetVoucherEligibility(query)
  const { adjuvants, fungicides, pesticides, herbicides, seeds } = useStaticDataStore()
  const { isCurrentSeason, isLoading: isPloughLoading } = usePloughSeason()

  const [transactionType, setTransactionType] = useState<string | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(true)

  if (!query) {
    return <NotFound />
  }

  if (isLoading || isPloughLoading) {
    return (
      <div className='grid place-items-center min-h-[30%] h-full flex-1'>
        <Loader />
      </div>
    )
  }

  if (error) {
    return <ErrorPage />
  }

  if (!data?.data.exists) {
    return <NotEligible />
  }

  const handleSeedFiltering = (): Seeds[] => {
    const seedsToShow = (
      data?.data.grant_cropping_plans_data as SeedDealerGrantCroppingPlanData[]
    ).filter((crops) => {
      return {
        crop_id: crops.crop_id,
        crop_type: crops.crop_type
      }
    })

    return (
      seeds?.filter((seed) => {
        const res = find(seedsToShow, { crop_id: seed.crop_id, crop_type: seed.seed_type })
        return res != null && seed.isactive === 1 && seed.price_per_kg !== 0.0
      }) ?? []
    )
  }

  const handleChemicalFiltering = (): ChemicalData[] => {
    const chemicalsToShow =
      (data?.data.grant_cropping_plans_data as ChemicalDealerCroppingPlanData[])?.filter(
        (crops) => ({
          crop_id: crops.crop_id,
          crop_type: crops.crop_type,
          chemicals: crops.chemicals
        })
      ) ?? []

    const filteredChemicalData = chemicalsToShow.map((crops) => ({
      ...crops,
      ...filteredChemicals(crops, pesticides, fungicides, herbicides, adjuvants)
    }))

    const uniqueChemicals = new Set<string>()

    return filteredChemicalData.flatMap((cropData) => [
      ...(cropData.pesticides
        ?.filter(
          (pesticide) =>
            isCurrentSeason(pesticide.plough_season) &&
            !uniqueChemicals.has(pesticide.registration_no) &&
            uniqueChemicals.add(pesticide.registration_no)
        )
        .map((pesticide) => ({ ...pesticide, type: 'pesticide' })) ?? []),
      ...(cropData.herbicides
        ?.filter(
          (herbicide) =>
            isCurrentSeason(herbicide.plough_season) &&
            !uniqueChemicals.has(herbicide.registration_no) &&
            uniqueChemicals.add(herbicide.registration_no)
        )
        .map((herbicide) => ({ ...herbicide, type: 'herbicide' })) ?? []),
      ...(cropData.adjuvants
        ?.filter(
          (adjuvant) =>
            isCurrentSeason(adjuvant.plough_season) &&
            !uniqueChemicals.has(adjuvant.registration_no) &&
            uniqueChemicals.add(adjuvant.registration_no)
        )
        .map((adjuvant) => ({ ...adjuvant, type: 'adjuvant' })) ?? [])
    ]) as ChemicalData[]
  }

  const renderContent = (): ReactElement | null => {
    if (transactionType === 'seeds') {
      const filteredSeeds = handleSeedFiltering()
      return (
        <>
          <Text size='medium' variant='bodyTextLight'>
            Below is a list of seeds eligible for redemption.
          </Text>
          <SeedsTable columns={seedsColumns(t)} data={filteredSeeds} />
        </>
      )
    } else if (transactionType === 'chemicals') {
      const combinedChemicals = handleChemicalFiltering()
      return (
        <>
          <Text size='medium' variant='bodyTextLight'>
            Below is a list of chemicals eligible for redemption.
          </Text>
          <ChemicalsTable columns={chemicalsColumns()} data={combinedChemicals} />
        </>
      )
    }

    return null
  }

  return (
    <div className='md:max-w-[800px] lg:max-w-[1200px] xl:max-w-[1700px] w-full flex-col gap-4 flex justify-center mx-auto px-0 py-4'>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <div className='flex justify-end'>
          <DialogTrigger asChild>
            <Button
              variant='primary'
              size='default'
              onClick={() => {
                setIsDialogOpen(true)
              }}
            >
              Choose Transaction Type
            </Button>
          </DialogTrigger>
        </div>
        <DialogContent>
          <Text size='large' className={'flex justify-center'}>
            What are you transacting?
          </Text>
          <div className='flex gap-4 mt-4 justify-center'>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant={'warning'}
                  size={'sm'}
                  onClick={() => {
                    setTransactionType('seeds');
                    setIsDialogOpen(false);
                  }}
                  disabled={flags.seedDealers === false}
                >
                  Seeds
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {flags.seedDealers === false && <p>Feature closed for this season</p>}
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant='success'
                  size={'sm'}
                  onClick={() => {
                    setTransactionType('chemicals');
                    setIsDialogOpen(false);
                  }}
                  disabled={flags.chemicalDealers === false}
                >
                  Chemicals
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {flags.chemicalDealers === false && <p>Feature closed for this season</p>}
              </TooltipContent>
            </Tooltip>
          </div>

        </DialogContent>
      </Dialog>

      <Text size='large'>Chemical & Seeds Voucher Redemption</Text>
      <Card className='w-full flex flex-col bg-card gap-4 md:gap-6 lg:gap-8 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
        {transactionType ? (
          renderContent()
        ) : (
          <Text>Please select a transaction type to proceed.</Text>
        )}
      </Card>
    </div>
  )
}
