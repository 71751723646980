import { useFarmerStore } from '../../../../stores/useFarmerStore'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type CreateGrantDTO, createNewGrant } from '../api/createGrant'
import { useToast } from 'components/ui'
import { AxiosError, type AxiosResponse } from 'axios'
import type { ApiResponse } from 'types'

interface UseNewGrants {
  isLoading: boolean
  newGrant: (grant: CreateGrantDTO, next: () => void) => void
}

export const useNewGrant = (): UseNewGrants => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()

  const createNewGrantMutation = useMutation({
    mutationFn: async (grant: CreateGrantDTO) => {
      return createNewGrant(grant)
    }
  })

  const newGrant = (grant: CreateGrantDTO, next: () => void): void => {
    createNewGrantMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cropping_plan: grant.cropping_plan,
        farm_id: grant?.farm_id,
        lease_id: grant.lease_id,
        user_id: farmer?.user_id,
        last_update_by: farmer?.user_id
      },
      {
        onSuccess: (resp) => {
          if (resp.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Grant Application Successful.'
            })
            void queryClient.invalidateQueries({
              queryKey: [`grant-${grant.farm_id ?? ''}`, farmer?.farmer_id]
            })
          }
        },
        onError: (err: unknown) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>> | undefined

            if (error?.status && error.status >= 500) {
              toast({
                variant: 'destructive',
                title: 'Uh oh! Something went wrong.'
              })
            } else {
              toast({
                variant: 'destructive',
                title: 'Error',
                description: error?.data.message ?? 'An unexpected error occurred.'
              })
            }
          }
        }
      }
    )
  }

  return {
    isLoading: createNewGrantMutation.isLoading,
    newGrant
  }
}
