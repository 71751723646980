import bwFlag from 'assets/landing/logos/Flag-Botswana.svg'
import moalogo from 'assets/landing/logos/moalogo.svg'
import { LandingButton } from '../LandingButton'
import { type FC, useEffect, useState } from 'react'
import { Button, Layout, Text } from 'components/ui'
import { Link, NavLink } from 'react-router-dom'
import { motion, useScroll, useTransform } from 'framer-motion'
import { LanguageToggle } from '../LanguageToggle'
import { Menu } from 'lucide-react'
import { useTranslation } from 'react-i18next'

interface NavLandingPageProps {
  goToDashboard: () => string
  isAuthenticated: boolean
}

export const NavLandingPage: FC<NavLandingPageProps> = ({ goToDashboard, isAuthenticated }) => {
  const [enabled, setEnabled] = useState(false)
  const { scrollY } = useScroll()
  const { t } = useTranslation('landing')

  const [background, setBackground] = useState('bg-white')

  useEffect(() => {
    const updateBackground = (): void => {
      const scrolledBackground = scrollY.get() > 50 ? 'bg-white' : 'bg-white'
      setBackground(scrolledBackground)
    }

    updateBackground()

    const unsubscribe = scrollY.onChange(updateBackground)
    return () => {
      unsubscribe()
    }
  }, [scrollY])

  const marginTop = useTransform(scrollY, [20, 0], [0, 30])
  const height = useTransform(scrollY, [0, 90], [110, 70])
  const borderColor = useTransform(
    scrollY,
    [0, 100],
    ['rgba(52,52,52,0)', 'rgba(157,157,157,0.73)']
  )

  const links = [
    { to: '/about-temo-letlotlo/', title: t('footer.temo') },
    { to: '/temo-cms/', title: t('footer.temoCMS') },
    { to: '/gallery/', title: t('footer.gal') },
    { to: '/contact-us', title: t('footer.con') }
  ]

  return (
    <Layout variant={'body'}>
      <div className='w-full bg-muted px-4 py-[4px] relative'>
        <Layout className={'flex gap-4 items-center'}>
          <img src={bwFlag} alt='bwFlag' className='h-[15px] sm:h-[20px]' />
          <Text size='small' className='text-grey-600'>
            {t('home.web')}
          </Text>
        </Layout>
      </div>
      <motion.div
        id={'navigation-main'}
        style={{
          background,
          height,
          marginTop,
          borderBottom: '1px solid',
          borderColor
        }}
        className={'fixed w-full flex flex-col h-[70px] z-[9999] top-0 left-0 right-0 bg-white'}
      >
        <Layout className={'h-full flex items-center justify-between px-4'}>
          <div className='flex gap-4 items-center'>
            <Link to={'/'}>
              {/* <img className='w-[clamp(3.5rem,12vw,4rem)]' src={moalogo} alt={moalogo} /> */}
              <img className='w-16 h-16' src={moalogo} alt={moalogo} />
            </Link>
          </div>
          <div className='hidden md:flex gap-6 justify-between'>
            {links.map((link, index) => (
              <NavLink
                to={link.to}
                className={({ isActive }) =>
                  isActive
                    ? 'font-semibold text-blue-400 capitalize '
                    : 'font-semibold text-gray-600 hover:text-blue-400 capitalize'
                }
              >
                {link.title}
              </NavLink>
            ))}
          </div>
          <div className='flex gap-2'>
            <LanguageToggle />
            <Menu
              className=' text-blue-800 cursor-pointer bg-transparent ml-4 flex md:hidden'
              size={42}
              onClick={() => {
                setEnabled(!enabled)
              }}
            />

            <Link to={goToDashboard()} className='hidden md:block'>
              <Button variant='primary' size='lg' className='w-fit px-4 py-2 rounded-lg'>
                <p className='text-xs md:text-sm lg:text-base font-bold text-white'>
                  {!isAuthenticated ? t('footer.login') : t('footer.dash')}
                </p>
              </Button>
            </Link>
            <div
              className={
                enabled
                  ? 'bg-white flex items-start p-8 flex-col absolute w-full h-screen z-[834789374857348] top-0 inset-x-0 transition-all duration-[0.3s] ease-[ease-in]'
                  : 'hidden h-0'
              }
            >
              <div className='w-full flex justify-end'>
                <Button
                  variant={'ghost'}
                  onClick={() => {
                    setEnabled(!enabled)
                  }}
                >
                  <Text
                    variant={'error'}
                    size='large'
                    className='material-symbols-outlined hover:text-destructive'
                  >
                    {t('footer.close')}
                  </Text>
                </Button>
              </div>

              <div className='w-full flex flex-col gap-12 items-start p-8 '>
                {links.map((link, index) => (
                  <NavLink
                    to={link.to}
                    className={({ isActive }) =>
                      isActive
                        ? 'font-bold text-blue-400 capitalize '
                        : 'font-bold text-gray-600 hover:text-blue-400 capitalize'
                    }
                  >
                    <h6 className='text-4xl flex justify-start text-start'>{link.title}</h6>
                  </NavLink>
                ))}
              </div>
              <div className='flex justify-start w-full px-8'>
                <Link to={goToDashboard()} className='w-full '>
                  <LandingButton
                    variant='filled'
                    size='large'
                    className='w-full px-4 py-2 rounded-xl'
                    title={!isAuthenticated ? t('footer.login') : t('footer.dash')}
                  />
                </Link>
              </div>
            </div>
          </div>
        </Layout>
      </motion.div>
    </Layout>
  )
}

