import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { LoanElligibility } from './LoanElligibility'
import { useGetFarms } from '../../../../farmer/land-management/api/getFarms'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useGetCroppingPlans } from '../../../api/getCroppingPlans'
import { Button, Loader, Text } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { usePloughSeason } from '../../../../../hooks/usePloughSeason'
import { EligibilityContainer } from '../elegiblityContainer'

interface ModalStepperProps {
  onClose: () => void
}

export const ModalStepper: React.FC<ModalStepperProps> = ({ onClose }) => {
  const { farmer } = useFarmerStore()
  const navigate = useNavigate()
  const { t } = useTranslation('loanManagement')
  const { data: farms, isLoading: farmsLoading } = useGetFarms(farmer?.farmer_id)
  const { data: croppingPlans, isLoading: croppingPlansLoading } = useGetCroppingPlans(
    farmer?.farmer_id as number
  )

  const { isCurrentSeason } = usePloughSeason()

  // Consider both types of data to be loading
  const isLoading = farmsLoading || croppingPlansLoading

  const onSubmit = (): void => {
    navigate('/farmer/loans/newloan')
  }

  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-full'>
        <Loader /> {/* Adjust as necessary for your Spinner component */}
      </div>
    )
  }

  // no farms
  if (farms == null || farms.data.length === 0) {
    const reasons = ['You do not have any farm(s).']
    return (
      <EligibilityContainer
        reasons={
          <LoanElligibility key={'no-farm'} isEligible={false} notEligibleReasons={reasons} />
        }
      >
        <Button variant={'secondary'} size={'xs'} onClick={onClose} className='w-32'>
          {t('home.cancel')}
        </Button>
        <Button variant={'destructive'} size='xs' onClick={onSubmit} disabled className='w-32'>
          <Text variant='colorText'>{t('home.cont')}</Text>
        </Button>
      </EligibilityContainer>
    )
  }

  // no verified farm
  if (farms?.data?.find((farm) => farm.status === 'verified') == null) {
    const reasons = ['No verified farm(s) found.']
    return (
      <EligibilityContainer
        reasons={
          <LoanElligibility
            key={'no-verified-farm'}
            isEligible={false}
            notEligibleReasons={reasons}
          />
        }
      >
        <Button variant={'secondary'} size={'xs'} onClick={onClose} className='w-32'>
          {t('home.cancel')}
        </Button>
        <Button variant={'destructive'} size='xs' onClick={onSubmit} disabled className='w-32'>
          <Text variant='colorText'>{t('home.cont')}</Text>
        </Button>
      </EligibilityContainer>
    )
  }

  // No cropping plans
  if (croppingPlans == null || croppingPlans.data.length === 0) {
    const reasons = ['No cropping plans found.']
    return (
      <EligibilityContainer
        reasons={<LoanElligibility key={'no-cp'} isEligible={false} notEligibleReasons={reasons} />}
      >
        <Button variant={'secondary'} size={'xs'} onClick={onClose} className='w-32'>
          {t('home.cancel')}
        </Button>
        <Button variant={'destructive'} size='xs' onClick={onSubmit} disabled className='w-32'>
          <Text variant='colorText'>{t('home.cont')}</Text>
        </Button>
      </EligibilityContainer>
    )
  }

  // No Approved cropping plans
  if (croppingPlans?.data?.find((cp) => cp.is_verified === 'Approved') == null) {
    const reasons = ['No approved cropping plans found.']
    return (
      <EligibilityContainer
        reasons={
          <LoanElligibility key={'approved-cp'} isEligible={false} notEligibleReasons={reasons} />
        }
      >
        <Button variant={'secondary'} size={'xs'} onClick={onClose} className='w-32'>
          {t('home.cancel')}
        </Button>
        <Button variant={'destructive'} size='xs' onClick={onSubmit} disabled className='w-32'>
          <Text variant='colorText'>{t('home.cont')}</Text>
        </Button>
      </EligibilityContainer>
    )
  }

  // No Approved cropping plans this plough season
  if (
    croppingPlans?.data?.find(
      (cp) => cp.is_verified === 'Approved' && isCurrentSeason(cp.plough_season)
    ) == null
  ) {
    const reasons = ['You do not have any valid cropping plans for this ploughing season.']
    return (
      <EligibilityContainer
        reasons={
          <LoanElligibility key={'plough-season'} isEligible={false} notEligibleReasons={reasons} />
        }
      >
        <Button variant={'secondary'} size={'xs'} onClick={onClose} className='w-32'>
          {t('home.cancel')}
        </Button>
        <Button variant={'destructive'} size='xs' onClick={onSubmit} disabled className='w-32'>
          <Text variant='colorText'>{t('home.cont')}</Text>
        </Button>
      </EligibilityContainer>
    )
  }

  return (
    <EligibilityContainer
      reasons={<LoanElligibility key={'eligible'} isEligible={true} notEligibleReasons={[]} />}>
      <Button variant={'secondary'} size={'xs'} onClick={onClose} className='w-32'>
        {t('home.cancel')}
      </Button>
      <Button variant={'success'} size='xs' onClick={onSubmit} className='w-32'>
        <Text variant='colorText'>{t('home.cont')}</Text>
      </Button>
    </EligibilityContainer>
  )
}
