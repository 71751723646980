import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorPage } from '../Errors/ErrorPage'
import Session, { SessionAuth } from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import { useAuthenticationStore } from '../../stores/useAuthenticationStore'
import { useGetFarmer } from '../../features/authentication/api/getFarmer'
import { useFarmerStore } from '../../stores/useFarmerStore'
import { getRole } from '../../utils/utilFunctions'
import { Unauthorized } from '../Errors/Unauthorized'
import { usePloughSeason } from '../../hooks/usePloughSeason'
import { Navigate, useNavigate } from 'react-router-dom'
import logo from '../../assets/logos/moa.svg'
import { useStaticDataStore } from '../../stores/useStaticDataStore'
import { NoAccount } from '../Errors/NoAccount'

const FarmerDashboard = React.lazy(async () => import('../Dashboard/Farmer'))

export const Farmer: React.FC = () => {
  const navigate = useNavigate()
  const sessionContext = Session.useSessionContext()
  const { setRole, isLogout } = useAuthenticationStore()
  const { setFarmer } = useFarmerStore()
  const claimValue = Session.useClaimValue(UserRoleClaim)
  const { setStaticData } = useStaticDataStore()

  const {
    data: farmerData,
    isInitialLoading: loadingFarmer,
    error: farmerError
  } = useGetFarmer(
    sessionContext.loading ? null : sessionContext.userId,
    claimValue.loading ? null : getRole(claimValue.value)
  )

  const { isLoading, staticData, agroData, staticInvData, banks } = usePloughSeason(1000)

  useEffect(() => {
    if (farmerData != null) {
      setRole(claimValue.loading ? null : getRole(claimValue.value))
      setFarmer(farmerData?.data[0])
    }

    if (staticData != null || agroData != null || staticInvData != null || banks != null) {
      setStaticData(staticData, agroData, staticInvData, banks)
    }
  }, [farmerData, staticData, agroData, staticInvData, banks])

  if (sessionContext.loading || claimValue.loading || loadingFarmer || isLoading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <img
          role={'presentation'}
          className='h-[4rem] md:h-[7rem] xl:h-[7.5rem] sm:h-[clamp(3rem,10vw,4rem)] hover:transform hover:scale-[1.005] cursor-pointer hover:duration-500 animate-bounce transition-transform'
          src={logo}
          alt={logo}
        />
      </div>
    )
  }

  if (getRole(claimValue.value) == null) {
    return <NoAccount />
  }

  if (farmerError != null) {
    if (farmerError.status === 404) {
      return <NoAccount />
    }
    return <ErrorPage />
  }

  if (!sessionContext.doesSessionExist && (isLogout === false || isLogout === null)) {
    return <Navigate to='/session'></Navigate>
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <SessionAuth
        accessDeniedScreen={Unauthorized}
        onSessionExpired={() => {
          navigate('/session')
        }}
        overrideGlobalClaimValidators={(globalValidators) => [
          ...globalValidators,
          UserRoleClaim.validators.includes('FARMER')
        ]}
      >
        <FarmerDashboard />
      </SessionAuth>
    </ErrorBoundary>
  )
}
