import type React from 'react'
import { useState } from 'react'
import type { Table } from '@tanstack/react-table'
import { RedeemVoucherOtpForm } from '../../components/RedeemVoucherOtpForm'
import { VoucherRedeemed } from '../../components/VoucherRedeemed'
import { useRedeemChemicalsVoucher } from '../hooks/useReedeemChemicalsVoucher'
import { type SelectedChemicals } from '../types'
import { ChemicalVoucherForm } from './ChemicalVoucherForm'
import { NotAvailable } from '../../../../components/Layout/NotAvailable'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface VoucherRedemptionProps<TData> {
  table: Table<TData>
}

export function ChemicalVoucherRedemption<TData>({
  table
}: VoucherRedemptionProps<TData>): React.ReactNode {
  const flags = useFlags()

  const { isLoading, getContact, contact, isOtpSent, isVoucherRedeemed, voucher, redeemVoucher } =
    useRedeemChemicalsVoucher()
  const [selectedChemicals, setSelectedChemicals] = useState<SelectedChemicals[]>([])

  const redeem = (): void => {
    redeemVoucher(selectedChemicals)
  }
  if (
    (flags.chemicalDealers === false)
  ) {
    return <NotAvailable />
  }

  if (isVoucherRedeemed) {
    return <VoucherRedeemed voucher={voucher} />
  }

  if (isOtpSent) {
    return <RedeemVoucherOtpForm contact={contact ?? ''} redeem={redeem} />
  }

  return (
    <ChemicalVoucherForm
      loading={isLoading}
      getContact={getContact}
      setSelectedData={setSelectedChemicals}
      className={'flex flex-col gap-4'}
      table={table}
    />
  )
}
