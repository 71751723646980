import { Grow, useMediaQuery } from '@mui/material'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { useGetInventory } from '../api/getInventory'
import { InventoryFilterMenu } from '../components/generic/InventoryFilterMenu'
import { type GetInventoryRes, type InventoryTransfer } from '../types'
import { Box } from 'components/App/Layout'
import { Loader, Tabs, TabsContent, TabsList, TabsTrigger, Text } from 'components/ui'
import { FolderX } from 'lucide-react'
import { InventoryCard } from '../components/InventoryCard'
import { TransferRequestCard } from '../components/AcceptRejectTransfer/TransferRequestCard'
import { useGetTransferRequest } from '../api/getInventoryTransfer'
import { NewInventory } from '../components/generic/NewInventory'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGetVerifiedFarms } from '../util'
import { ComingSoon } from '../../../../components/Layout/ComingSoon'

export const Inventories: React.FC = () => {
  const navigate = useNavigate()
  const isSmallScreen = useMediaQuery('(max-width:945px)')
  const { farmer } = useFarmerStore()
  const [verified, setVerified] = useState<GetInventoryRes[] | null>(null)
  const [pending, setPending] = useState<GetInventoryRes[] | null>(null)
  const [rejected, setRejected] = useState<GetInventoryRes[] | null>(null)
  const [reassessment, setReassesment] = useState<GetInventoryRes[] | null>(null)
  const [onTransfer, setOnTransfer] = useState<InventoryTransfer[] | null>(null)
  const [filter, setFilter] = useState<
    | 'all'
    | 'VERIFIED'
    | 'PENDING_VERIFICATION'
    | 'REJECTED'
    | 'Transfer initiated'
    | 'TO BE REASSESSED'
  >('all')
  const flags = useFlags()
  const ldClient = useLDClient()
  const { t } = useTranslation('inventory')
  const farmsData = useGetVerifiedFarms()
  const { data, isLoading } = useGetInventory(farmer?.farmer_id)

  const { data: transferRequest, isLoading: transferLoading } = flags.inventoryTransfer
    ? useGetTransferRequest(farmer?.farmer_id)
    : { data: null, isLoading: false }

  const handleInventorySubmit = (selectedInventory: string): void => {
    navigate(`/farmer/inventory/register-new/${selectedInventory}`)
  }

  const isTransfer = (inventoryId: string): boolean => {
    return (
      transferRequest?.data.find(
        (transfer) =>
          transfer.inventory_id.toString() === inventoryId.toString() &&
          (transfer.transaction_status === 1 || transfer.transaction_status === 4)
      ) != null
    )
  }

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })

    if (flags.inventoryTransfer && transferRequest?.status === 200) {
      setOnTransfer(
        transferRequest?.data.filter(
          (res) =>
            (res.transaction_status === 1 || res.transaction_status === 4) &&
            res.origin_farmer_id === farmer?.farmer_id
        )
      )
    }

    if (data?.status === 200) {
      setVerified(data?.data.filter((res) => res.item_verification_status === 'VERIFIED'))
      setPending(
        data?.data.filter((res) => res.item_verification_status === 'PENDING_VERIFICATION')
      )
      setRejected(data?.data.filter((res) => res.item_verification_status === 'REJECTED'))
      setReassesment(
        data?.data.filter((res) => res.item_verification_status === 'TO BE REASSESSED')
      )
    }
  }, [farmer, data, ldClient, transferRequest, flags.inventoryTransfer])

  if (flags.inventory === false) {
    return <ComingSoon />
  }

  if (isLoading || (flags.inventoryTransfer && transferLoading)) {
    return (
      <div className={'h-full grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  return (
    <Box>
      <div className={'flex justify-between items-center'}>
        <Text size={'large'}>{t('inventory.my')}</Text>
        <NewInventory
          onSubmit={handleInventorySubmit}
          disabled={farmsData?.verifiedFarms?.length === 0}
        />
      </div>

      <Tabs defaultValue='Inventories' className='w-full'>
        {flags.inventoryTransfer && (
          <TabsList className='grid max-w-[400px] w-full grid-cols-2'>
            <TabsTrigger value='Inventories'>{t('inventory.inventory')}</TabsTrigger>
            <TabsTrigger value='Tranfers'>{t('inventory.transfers')}</TabsTrigger>
          </TabsList>
        )}
        {flags.inventoryTransfer && (
          <TabsContent value='Tranfers' className={'w-full'}>
            <div className={'mt-4 w-full flex flex-col gap-4'}>
              {transferRequest != null && transferRequest?.data?.length > 0 ? (
                <>
                  <Text
                    size={'medium'}
                    variant={'bodyTextLight'}
                    className={'flex mt-5 mb-5 justify-center items-center'}
                  >
                    {t('transfer.request')}
                  </Text>
                  <div
                    className={`grid gap-6 lg:grid-cols-3 ${
                      isSmallScreen ? 'grid-cols-1' : 'grid-cols-2'
                    }`}
                  >
                    {transferRequest?.data
                      .filter(
                        (item) =>
                          (item.transaction_status === 1 || item.transaction_status === 4) &&
                          item.dest_farmer_id === farmer?.farmer_id
                      )
                      .map((offer) => (
                        <TransferRequestCard key={offer.transfer_id} transferRequest={offer} />
                      ))}
                  </div>
                </>
              ) : (
                <div className={'flex flex-col gap-4 items-center justify-center mt-20'}>
                  <Text size={'xLarge'} className={'opacity-40'}>
                    <FolderX />
                  </Text>
                  <Text size={'medium'} variant={'bodyTextLight'}>
                    You do not have any Transfer Requests
                  </Text>
                </div>
              )}
            </div>
          </TabsContent>
        )}
        <TabsContent value='Inventories' className={'w-full'}>
          <div className={'mt-4 w-full flex flex-col gap-4'}>
            {data?.data === undefined ? (
              <div className={'flex flex-col gap-4 items-center justify-center mt-20'}>
                <Text size={'xLarge'} className={'opacity-40'}>
                  <FolderX />
                </Text>
                {farmsData?.verifiedFarms?.length === 0 ? (
                  <Text size={'medium'} variant={'bodyTextLight'}>
                    You do not have a verified Farm/Lease
                  </Text>
                ) : (
                  <Text size={'medium'} variant={'bodyTextLight'}>
                    {t('inventory.none')}
                  </Text>
                )}
              </div>
            ) : (
              <>
                <InventoryFilterMenu
                  all={data.data.length}
                  verified={verified?.length ?? 0}
                  pending={pending?.length ?? 0}
                  filterBy={filter}
                  setFilterBy={setFilter}
                  rejected={rejected?.length ?? 0}
                  onTransfer={onTransfer?.length ?? 0}
                  reassesment={reassessment?.length ?? 0}
                />

                <Grow in={true}>
                  <div
                    className={`grid gap-6 lg:grid-cols-2 ${
                      isSmallScreen ? 'grid-cols-1' : 'grid-cols-2'
                    }`}
                  >
                    {!isLoading &&
                      !transferLoading &&
                      data.data
                        .filter((i) => {
                          if (filter === 'all') return true
                          if (filter === 'Transfer initiated') {
                            return onTransfer?.some(
                              (transfer) => transfer.inventory_id === i.inventory_id
                            )
                          }
                          return i.item_verification_status === filter
                        })
                        .map((item) => {
                          return (
                            <InventoryCard
                              key={item.inventory_id}
                              inventoryData={item}
                              isTransfer={isTransfer(item.inventory_id.toString())}
                            />
                          )
                        })}
                  </div>
                </Grow>
              </>
            )}
          </div>
        </TabsContent>
      </Tabs>
    </Box>
  )
}
