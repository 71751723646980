import type React from 'react'
import { useEffect, useState } from 'react'
import { Button, Card, Text } from 'components/ui'
import { SearchBar } from 'components/Form/SearchBar'
import { Loader } from 'components/ui/loader'
import { useCheckVoucherEligibility } from '../hooks/useCheckVoucherEligibility'
import { useAuthenticationStore } from '../../../stores/useAuthenticationStore'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMerchantStore } from '../../../stores/useMerchantStore'
import { useGetSPInventory } from '../service-provider/inventory/api/getSPInventory'
import { useTranslation } from 'react-i18next'
import type { Tractor } from '../../farmer/inventory/types'
import { useStaticDataStore } from '../../../stores/useStaticDataStore'
import { findMatchingItem } from '../../farmer/inventory/util/findMatchingItem'
import { useNavigate } from 'react-router-dom'
import { Info } from 'lucide-react'
import { NotAvailable } from '../../../components/Layout/NotAvailable'

interface SearchEligibilityProps {
  route: string
}

export const SearchEligibility: React.FC<SearchEligibilityProps> = ({ route }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [message, setMessage] = useState<string>('')
  const { t } = useTranslation('merchant')
  const flags = useFlags()
  const { role } = useAuthenticationStore()
  const { merchant } = useMerchantStore()
  const { tractors, nonPoweredImplements, poweredImplements, setHasTractor } = useStaticDataStore()
  const navigate = useNavigate()
  const { checkVoucherEligibility, isEligible, error, isLoading } = useCheckVoucherEligibility(
    `${route}?omang=${searchQuery}`
  )
  const [hasInventory, setHasInventory] = useState(false)

  const { data } =
    role === 2001 && merchant?.merchant_id
      ? useGetSPInventory(merchant.merchant_id)
      : { data: null }

  useEffect(() => {
    if (data && tractors != null && nonPoweredImplements != null && poweredImplements != null) {
      const hasTractor = data.data.some(
        (item) =>
          item.item_verification_status === 'VERIFIED' &&
          ((findMatchingItem(item, tractors, nonPoweredImplements, poweredImplements) as Tractor)
            ?.tractor_type === 'CONVENTIONAL' ||
            (findMatchingItem(item, tractors, nonPoweredImplements, poweredImplements) as Tractor)
              ?.tractor_type === 'WALKING')
      )

      // const hasPrimaryImplement = data.data.some(
      //   (item) =>
      //     item.item_verification_status === 'VERIFIED' &&
      //     (findMatchingItem(item, tractors, nonPoweredImplements, poweredImplements) as Implement)
      //       ?.implement_class === 'PRIMARY'
      // )

      const inventoryStatus = hasTractor

      setHasInventory(inventoryStatus)
      setHasTractor(inventoryStatus)

      if (!inventoryStatus) {
        const missingItems = []
        if (!hasTractor) missingItems.push('Tractor')
        // if (!hasPrimaryImplement) missingItems.push('Plough')

        setMessage(`To transact you need a verified ${missingItems.join(', ')}`)
      }
    }
  }, [data, tractors, nonPoweredImplements, poweredImplements])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value)
  }

  if (
    (role === 2004 && flags.seedDealers === false) ||
    (role === 2001 && flags.serviceProviders === false) ||
    (role === 2003 && flags.chemicalDealers === false) ||
    (role === 2002 && (flags.chemicalDealers === false && flags.seedDealers === false))
  ) {
    return <NotAvailable />
  }

  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4 flex justify-center mx-auto px-0 py-4'}>
      <Text size={'large'}>{t('merchant.vr')}</Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        {role === 2001 && flags.merchantInventoryTransaction && !hasInventory ? (
          <div className='flex flex-col items-center justify-center gap-4'>
            <Text size={'medium'} className={'text-center'}>
              {message}
            </Text>
            <div className='flex items-center'>
              <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
              <Text variant='secondary' size='small' className=''>
                {`You need to have a Tractor that is verified to transact ${
                  flags.merchantInventory === false
                    ? 'however inventory registration is unavailable at the moment'
                    : ''
                }`}
              </Text>
            </div>
            {flags.merchantInventory && (
              <Button
                size='xs'
                variant='success'
                onClick={() => {
                  navigate('/service-provider/inventory')
                }}
              >
                View Inventory
              </Button>
            )}
          </div>
        ) : (
          <div className={'flex flex-col gap-2'}>
            <Text size={'medium'} variant={'bodyTextLight'}>
              {t('merchant.check')}
            </Text>
            <SearchBar
              className={'w-full max-w-[700px] flex gap-8'}
              searchQuery={searchQuery}
              disabled={searchQuery.length !== 9}
              onChange={handleChange}
              onSearch={() => {
                void checkVoucherEligibility(searchQuery)
              }}
              isSearching={isLoading}
            />
          </div>
        )}

        {isLoading ? (
          <div className={'mt-8 grid place-items-center h-full flex-1'}>
            <Loader />
          </div>
        ) : null}

        {error !== null ? (
          <Text size={'medium'} variant={'error'} className={'ml-4'}>
            {error}
          </Text>
        ) : null}

        {error === null && isEligible != null && !isEligible ? (
          <Text className={'text-center'} variant={'primary'} size={'medium'}>
            {t('merchant.noV')}
          </Text>
        ) : null}
      </Card>
    </div>
  )
}
