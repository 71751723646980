import { type FC, useState } from 'react'
import { Dialog, DialogContent, DropdownMenuContent, DropdownMenuItem, Text } from 'components/ui'
import { Check, Trash2 } from 'lucide-react'
import { type Lease } from 'types'
import { useAcceptOrRejectLease } from '../../hooks/useAcceptOrRejectLease'
import { LoadingDialog } from 'components/ui/loading-dialog'
import { AcceptTermination } from '../../../land-management/components/terminate-lease/AcceptTermination'
import { useTranslation } from 'react-i18next'

interface AcceptOrRejectLeaseProps {
  lease: Lease
}

export const AcceptOrRejectLease: FC<AcceptOrRejectLeaseProps> = ({ lease }) => {
  const [open, setOpen] = useState(false)
  const [openTermination, setOpenTermination] = useState(false)
  const { isLoading, acceptLeaseOffer, rejectLeaseOffer } = useAcceptOrRejectLease(lease)
  const { t } = useTranslation('leaseManagement')

  const isTermination = lease.status_desc === 'TERMINATION INITIATED BY LESSOR'

  const handleCloseOpen = (): void => {
    setOpen(false)
  }
  const handleOpenTermination = (): void => {
    setOpenTermination(true)
  }
  const handleCloseTermination = (): void => {
    setOpenTermination(false)
  }

  const acceptLease = (): void => {
    acceptLeaseOffer(handleCloseOpen)
  }

  const rejectLease = (): void => {
    rejectLeaseOffer(handleCloseOpen)
  }

  return (
    <>
      <DropdownMenuContent className={'bg-popover-dark w-[10rem] p-2'}>
        <DropdownMenuItem
          onClick={isTermination ? handleOpenTermination : acceptLease}
          disabled={isLoading}
        >
          <Text>
            <Check className={'h-[1rem] text-success'} />
          </Text>
          <Text>{t('leaseForm.acc')}</Text>
        </DropdownMenuItem>
        {isTermination ? null : (
          <DropdownMenuItem onClick={rejectLease} disabled={isLoading}>
            <Text>
              <Trash2 className={'h-[1rem] text-destructive'} />
            </Text>
            <Text>{t('leaseForm.rej')}</Text>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
      <LoadingDialog key={'lease'} modal defaultOpen={false} open={isLoading} />

      <Dialog
        key={'accept-terminate'}
        open={openTermination}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseTermination}
      >
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          <AcceptTermination lease={lease} next={handleCloseTermination} />
        </DialogContent>
      </Dialog>
    </>
  )
}