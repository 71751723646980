import { CroppingPlanResults } from 'features/officer/cropping-plan/components/results/CroppingPlanResults'
import { useSearchCroppingPlan } from 'features/officer/cropping-plan/hooks/useSearchCroppingPlan'
import type React from 'react'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { useOfficerStore } from 'stores/useOfficerStore'
import { Card, Loader, Text } from 'components/ui'
import { useParams, useSearchParams } from 'react-router-dom'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { NotFound } from 'components/Errors/NotFound'
import { usePloughSeason } from '../../../../hooks/usePloughSeason'
import { useTranslation } from 'react-i18next'
import { NotAvailable } from '../../../../components/Layout/NotAvailable'

export const SearchCroppingPlan: React.FC = () => {
  const [searchParams] = useSearchParams()
  const query = searchParams?.get('q')
  const { farmerId } = useParams<{ farmerId: string }>()
  const { t } = useTranslation('extensionOfficer')
  const { officer } = useOfficerStore()
  const { searchCroppingPlan, data, error, isLoading } = useSearchCroppingPlan(officer?.ext_area_id)
  const { isCurrentSeason } = usePloughSeason()

  const flags = useFlags()
  const ldClient = useLDClient()

  useEffect(() => {
    if (farmerId != null) {
      void searchCroppingPlan(farmerId)
    }
    if (error !== null) {
      toast.error(error, { id: error })
    }

    void ldClient?.identify({ key: 'moa-frontend' })

    // on page refresh search based on query param
    if (farmerId !== '' && query != null && !isLoading) {
      void searchCroppingPlan(farmerId ?? '')
    }
  }, [error])

  if (flags.croppingPlanOfficer === false) {
    return <NotAvailable />
  }

  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'}>
      <Text size={'large'} className={'ml-2'}>
        {t('cp.cpV')}
      </Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        {isLoading ? (
          <div className={'mt-8 grid place-items-center h-full flex-1'}>
            <Loader />
          </div>
        ) : null}

        {error !== null ? (
          <Text size={'medium'} variant={'error'} className={'ml-4'}>
            {error}
          </Text>
        ) : null}

        {error === null && !isLoading && data?.length === 0 ? (
          <Text className={'text-center'} variant={'primary'} size={'medium'}>
            {t('cp.nosr')}
          </Text>
        ) : null}

        {data !== null && data.length > 0 ? (
          <CroppingPlanResults
            croppingPlans={data.filter(
              (item) =>
                item.is_verified === 'Submitted CP Verification' &&
                isCurrentSeason(item.plough_season)
            )}
            className={'w-full flex flex-col gap-2'}
          />
        ) : null}
      </Card>
    </div>
  )
}
