import envelopeIcon from 'assets/landing/icons/email_icon.svg'
import enquiriesIcon from 'assets/landing/icons/enquiries_icon.svg'
import tractorKIcon from 'assets/landing/icons/tractor.svg'
import phoneIcon from 'assets/landing/icons/phoneIconTwo.svg'
import grantIcon from 'assets/landing/icons/grant.svg'
// import mapIcon from 'assets/landing/icons/pin.svg'

interface CardData {
  icon: string
  title: string
  description: string
  action: {
    text: string
    type: 'tel' | 'phone' | 'link'
  }
}

export const cardsData = (t: (key: string) => string): CardData[] => {
  return [
    {
      icon: enquiriesIcon,
      title: t('con.temo'),
      description: t('con.speak'),
      action: {
        text: '3689151',
        type: 'phone'
      }
    },
    {
      icon: tractorKIcon,
      title: 'Tractor KYC Update',
      description: 'For all tractor KYC updates.',
      action: {
        text: '3689365',
        type: 'phone'
      }
    },
    {
      icon: phoneIcon,
      title: 'Crop Management System (CMS)',
      description: 'Enquire or report issues with the CMS.',
      action: {
        text: '3689307',
        type: 'phone'
      }
    },
    {
      icon: grantIcon,
      title: 'Payments',
      description: 'Ask about grants, ,loans, and more.',
      action: {
        text: '3689358',
        type: 'phone'
      }
    }
    // {
    //   icon: phoneIcon,
    //   title: 'Contact a specific district',
    //   description: t('con.mon'),
    //   action: {
    //     text: t('con.view'),
    //     type: 'phone'
    //   }
    // }
    // {
    //   icon: mapIcon,
    //   title: 'Visit us',
    //   description: 'Visit our office HQ.',
    //   action: {
    //     text: t('con.visitUs'),
    //     type: 'link'
    //   }
    // }
  ]
}
