import React, { type FC } from 'react'
import type { Lease } from '../../../../../types'
import type z from 'zod'
import dayjs from 'dayjs'
import { coerce, object } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Calendar,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text
} from 'components/ui'
import { cn } from 'lib/utils'
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { useRenewLease } from '../../../hooks/useRenewLease'
import {
  maxLeaseYear,
  maxLeaseYearDate,
  minLeaseYearDate,
  minLeaseYearTaxPayerDate
} from 'utils/utilFunctions'
import { useTranslation } from 'react-i18next'

interface LeaseRenewalProps {
  lease: Lease
  next: () => void
}

export const LeaseRenewal: FC<LeaseRenewalProps> = ({ next, lease }) => {
  const { initiateRenewal, isLoading } = useRenewLease(next)
  const { t } = useTranslation('leaseManagement')

  const minLeaseYears = lease?.dest_farmer_class !== 'TAXPAYER' ? 1 : 5
  const minDate =
    lease?.dest_farmer_class !== 'TAXPAYER' ? minLeaseYearDate() : minLeaseYearTaxPayerDate()
  const maxDate = maxLeaseYearDate()
  const maxYear = maxLeaseYear()

  const leaseRenewalSchema = object({
    leaseSize: coerce
      .number({
        required_error: 'lease size is required',
        invalid_type_error: 'lease size should be a number'
      })
      .min(1, 'lease size should be greater than 1')
      .max(lease.farm_size),
    endDate: coerce.date()
  })
    .refine((data) => data.endDate >= minDate, {
      message: `End date should be at least ${minLeaseYears} year after today`,
      path: ['endDate']
    })
    .refine((data) => data.endDate <= maxDate, {
      message: `End date should be at most 10 years after today`,
      path: ['endDate']
    })

  const form = useForm<z.infer<typeof leaseRenewalSchema>>({
    resolver: zodResolver(leaseRenewalSchema),
    defaultValues: {
      leaseSize: lease.leased_size,
      endDate: dayjs(lease.expiry_date).toDate()
    }
  })

  const onSubmit = (data: z.infer<typeof leaseRenewalSchema>): void => {
    initiateRenewal(lease, {
      LEASED_AREA: data.leaseSize,
      TERMINATION_DATE: dayjs(data.endDate).format('YYYY-MM-DD'),
      LAST_UPDATED_BY: undefined,
      LEASE_ID: undefined,
      FARMER_ID: undefined
    })
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Text className={'text-center'} size={'large'} variant={'colorText'}>
          {t('ren.prompt')}
        </Text>

        <div className={'flex flex-col gap-4 md:gap-8'}>
          <FormField
            key={React.useId()}
            control={form.control}
            name={'leaseSize'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>{t('ren.size')}</FormLabel>
                <FormDescription>
                  {t('ren.max')}: {lease.farm_size}
                </FormDescription>
                <FormControl>
                  <Input
                    placeholder={t('ren.size')}
                    classLabel={'bg-background-dark sm:bg-card'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* date picker */}
          <FormField
            key={React.useId()}
            control={form.control}
            name='endDate'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>{t('ren.end')}</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={'outlined'}
                        type={'button'}
                        className={cn(
                          'text-left font-normal',
                          field.value != null && 'text-muted-foreground'
                        )}
                      >
                        {field.value != null ? (
                          format(field.value, 'PPP')
                        ) : (
                          <span>{t('ren.pick')}</span>
                        )}
                        <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className='w-auto p-0' align='start'>
                    <Calendar
                      initialFocus
                      mode='single'
                      captionLayout='dropdown-buttons'
                      toYear={maxYear}
                      disabled={(date) => date <= minDate || date >= maxDate}
                      fromDate={field.value > maxDate ? minDate : field.value}
                      selected={field.value}
                      onSelect={field.onChange}
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={'w-full flex justify-between gap-4 md:gap-8'}>
          <Button
            type={'button'}
            disabled={isLoading}
            variant={'secondary'}
            className={'w-1/2'}
            onClick={next}
          >
            {t('ren.cancel')}
          </Button>
          <Button disabled={isLoading} loading={isLoading} className={'w-1/2'} type={'submit'}>
            {t('ren.renew')}
          </Button>
        </div>
      </form>
    </Form>
  )
}