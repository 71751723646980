import { type ColumnDef } from '@tanstack/react-table'
import { type Grant } from 'types'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'
import dayjs from 'dayjs'

export const farmGrantsColumns: Array<ColumnDef<Grant>> = [
  {
    accessorKey: 'application date',
    filterFn: 'includesString',
    accessorFn: (row) => row.application_date,
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Application Date' />,
    cell: ({ row }) => (
      <div className='lowercase'>
        {dayjs(row.getValue('application date')).format('DD-MM-YYYY')}
      </div>
    )
  },
  {
    accessorKey: 'grant_tracking_no',
    accessorFn: (row) => row.grant_tracking_no === null ? 'N/A' :row.grant_tracking_no,
    header: ({ column }) => <DataTableColumnHeader column={column} title='NDB Tracking #' />
  },
  {
    accessorKey: 'plough season',
    accessorFn: (row) => row.plough_season  ,
    header: 'Plough Season'
  },

  {
    accessorKey: 'size',
    accessorFn: (row) => row.available_size,
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Size(ha)' />
  },
  {
    accessorKey: 'total costs',
    accessorFn: (row) => row.total_costs,
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Total Cost' />,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('total costs'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  }
]
