import type React from 'react'
import { motion } from 'framer-motion'
import { ContactCard } from './SingleContactCard'
import { cardsData } from './Data/ContactTypeData'
import { useTranslation } from 'react-i18next'

export const ContactCards: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <div className='flex flex-col py-32 md:py-40 px-10 bg-white space-y-8'>
      <div className='container mx-auto flex flex-col md:justify-center md:items-center space-y-6 mb-12'>
        <motion.div
          className='flex flex-col items-center mb-12'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 landing-page'>
            {t('con.us')}
          </h2>
          <p className='text-base md:text-xl lg:text-2xl font-regular text-center text-gray-600 md:px-[10vw] lg:px-[20vw] xl:px-0 landing-page'>
            {t('con.let')}
          </p>
        </motion.div>
      </div>
      <motion.div
        className='flex flex-col justify-center items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 '>
          {cardsData(t).map((card, index) => (
            <ContactCard key={index} card={card} />
          ))}
        </div>
      </motion.div>
    </div>
  )
}
